import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';

interface SizeState {
  mapArea1: { width: number; height: number };
  mapArea2: { width: number; height: number };
  mapArea3: { width: number; height: number };
}

interface SizeContextType {
  sizes: SizeState;
  setSizes: Dispatch<SetStateAction<SizeState>>;
}

const initialSizes: SizeState = {
  mapArea1: { width: 0, height: 0 },
  mapArea2: { width: 0, height: 0 },
  mapArea3: { width: 0, height: 0 },
};

const SizeContext = createContext<SizeContextType>({
  sizes: initialSizes,
  setSizes: () => {},
});

interface SizeProviderProps {
  children: ReactNode;
}

export const SizeProvider: React.FC<SizeProviderProps> = ({ children }) => {
  const [sizes, setSizes] = useState<SizeState>(initialSizes);
  const value = { sizes, setSizes };
  return <SizeContext.Provider value={value}>{children}</SizeContext.Provider>;
};

export const useSizes = () => useContext(SizeContext);
