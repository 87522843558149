import React from 'react';
import Gauge from './Gauge';
import styled from '@emotion/styled';
import { nth } from '../utils/math';
import {
  AdHocCollabScore,
  OccupancyScore,
  PlannedCollabScore,
} from './icons/Scores';
import { Tag } from 'antd';
import { ResultSummaries } from '../interfaces/types';
import Icon from '@mdi/react';
import { mdiOpenInNew } from '@mdi/js';
import { track } from '@amplitude/analytics-browser';

const Dials = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  gap: 10px;
  max-width: 500px; /* Max width of the container */
  width: 100%; /* Ensure it takes up the full width up to its max */
  margin: 0 auto 10px auto; /* Center the container */
  @media (max-width: 500px) {
    padding: 20px 0 10px 0;
  }
`;

const DialsPrimary = styled.div`
  border-radius: 10px;
  background-image: linear-gradient(45deg, #ffffff11, #ffffff22, #ffffff11);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0 10px 10px 10px;
  margin-left: 20px;
  display: flex;
  @media (max-width: 500px) {
    padding: 0 5px 10px 5px;
    margin-left: 10px;
  }

  div:nth-of-type(1) {
    max-width: 160px;
    height: 100%;
    align-items: center;
    display: flex;

    @media (max-width: 500px) {
      max-width: 120px;
    }
    @media (max-width: 400px) {
      max-width: 100px;
    }
  }

  div:nth-of-type(2) {
    flex: 1;
  }

  h3 {
    @media (max-width: 500px) {
      font-size: 12px;
      font-weight: 800;
    }
    font-weight: 400;

    .below-average {
      color: #e74c3c;
    }

    .good {
      color: #2ecc71;
    }

    .very-good {
      color: #1fbcff;
    }

    .excellent {
      color: #f39c12;
    }
  }

  p {
    line-height: 1.5;
  }
`;

const DialsSecondary = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;

  > div {
    margin: 5px 0;
    display: flex;
    width: 100%;
    align-items: center;

    div:nth-of-type(1) {
      max-width: 80px;
      @media (max-width: 400px) {
        max-width: 60px;
      }
    }

    h4 {
      margin: 10px 0 0;
      font-size: 12px;
      @media (max-width: 400px) {
        font-size: 11px;
      }
    }

    h5 {
      color: #ffffff99;
      font-size: 11px;
      font-weight: normal;
      margin: 0 0 8px 0;
      @media (max-width: 400px) {
        font-size: 10px;
      }
    }

    p {
      margin: 0px;

      small {
        font-size: 11px;
      }
    }

    span.ant-tag {
      margin-left: 5px;
      padding: 0 5px;
      font-size: 11px;
      @media (max-width: 400px) {
        font-size: 10px;
        line-height: 1.6em;
        padding: 0 4px;
      }
    }

    span.below-average {
      color: #e74c3c;
      background: #e74c3c11;
      border: 1px solid #e74c3c;
    }

    span.good {
      color: #2ecc71;
      background: #2ecc7111;
      border: 1px solid #2ecc71;
    }

    span.very-good {
      color: #1fbcff;
      background: #3498db11;
      border: 1px solid #1fbcff;
    }

    span.excellent {
      color: #f39c12;
      background: #f39c1211;
      border: 1px solid #f39c12;
    }
  }
`;

const IconWrapper = styled.div`
  color: #ffffff88;
  margin-top: 15px;
  margin-left: 10px;
  @media (max-width: 500px) {
    display: none;
  }
`;

interface ScoreSheetProps {
  occupancyScore: number;
  adhocCollabScore: number;
  plannedCollabScore: number;
  collabScore: number;
  summaries: ResultSummaries;
}

const ScoreSheet: React.FC<ScoreSheetProps> = ({
  occupancyScore,
  adhocCollabScore,
  plannedCollabScore,
  collabScore,
  summaries,
}) => {
  return (
    <Dials>
      <DialsPrimary>
        <div>
          <Gauge title="CollabScore" percent={collabScore / 100} />
        </div>
        <div style={{ padding: '0 10px' }}>
          <h3>
            Your CollabScore is:{' '}
            <strong className={scoreToText(collabScore, true)}>
              {scoreToText(collabScore)}
            </strong>
          </h3>
          <p>
            You're in the{' '}
            <strong>{collabScore + nth(collabScore)} percentile. </strong>
            {summaries.collabScoreSummary}
          </p>
          <p
            style={{
              fontSize: '11px',
              color: '#ffffffa5',
              margin: '6px 0 3px 0',
              lineHeight: '1.2',
            }}
          >
            This is an estimate. Your{' '}
            <a
              href={
                'https://robinpowered.com/solutions/in-person-collaboration'
              }
              target={'_blank'}
              rel={'noopener noreferrer'}
              style={{
                color: '#ffffffbf',
                textDecoration: 'underline',
              }}
              onClick={() => {
                track('opened-product-page', { origin: 'results-disclaimer' });
              }}
            >
              actual score
            </a>{' '}
            <Icon
              path={mdiOpenInNew}
              size={0.4}
              style={{ verticalAlign: 'middle' }}
            />{' '}
            may vary.
          </p>
        </div>
      </DialsPrimary>
      <div></div>
      <DialsSecondary>
        <div>
          <div style={{ flex: 0 }}>
            <Gauge title="Occupancy" percent={occupancyScore / 100} />
          </div>
          <div style={{ flex: 1 }}>
            <h4>
              Workplace Occupancy
              <Tag className={scoreToText(occupancyScore, true)}>
                {scoreToText(occupancyScore)}
              </Tag>
            </h4>
            <h5>{occupancyScore + nth(occupancyScore)} percentile</h5>
            <p>
              <small>{summaries.occupancySummary}</small>
            </p>
          </div>
          <IconWrapper>
            <OccupancyScore />
          </IconWrapper>
        </div>
        <div>
          <div style={{ flex: 0 }}>
            <Gauge title="Ad Hoc Collab" percent={adhocCollabScore / 100} />
          </div>
          <div style={{ flex: 1 }}>
            <h4>
              Ad Hoc Collaboration
              <Tag className={scoreToText(adhocCollabScore, true)}>
                {scoreToText(adhocCollabScore)}
              </Tag>
            </h4>
            <h5>{adhocCollabScore + nth(adhocCollabScore)} percentile</h5>
            <p>
              <small>{summaries.adhocSummary}</small>
            </p>
          </div>
          <IconWrapper>
            <AdHocCollabScore />
          </IconWrapper>
        </div>
        <div>
          <div style={{ flex: 0 }}>
            <Gauge title="Planned Collab" percent={plannedCollabScore / 100} />
          </div>
          <div style={{ flex: 1 }}>
            <h4>
              Planned Collaboration
              <Tag className={scoreToText(plannedCollabScore, true)}>
                {scoreToText(plannedCollabScore)}
              </Tag>
            </h4>
            <h5>{plannedCollabScore + nth(plannedCollabScore)} percentile</h5>
            <p>
              <small>{summaries.plannedSummary}</small>
            </p>
          </div>
          <IconWrapper>
            <PlannedCollabScore />
          </IconWrapper>
        </div>
      </DialsSecondary>
      <div></div>
    </Dials>
  );
};

export default ScoreSheet;

const scoreToText = (score: number, className = false) => {
  if (score < 40) {
    return className ? 'below-average' : 'Below Average';
  } else if (score < 70) {
    return className ? 'good' : 'Good';
  } else if (score < 85) {
    return className ? 'very-good' : 'Very Good';
  } else {
    return className ? 'excellent' : 'Excellent';
  }
};
