export const getRoomOccupancyRate = (
  desks: number,
  rooms: number,
  privateOffices: number,
  occupancy: number,
  avgMeetingsPerDay: number,
  roomCollaborationFactor: number
) => {
  const totalOccupiedDesks = desks * (occupancy / 100); // Total population
  const avgMeetingSize = getAverageMeetingSize(roomCollaborationFactor);

  const roomsNeeded = getRoomsNeeded(
    totalOccupiedDesks,
    privateOffices,
    avgMeetingsPerDay,
    avgMeetingSize
  );

  return Math.min(Math.max(roomsNeeded / (rooms - privateOffices), 0), 1);
};

export const getAverageMeetingSize = (roomCollaborationFactor: number) => {
  return 1 + (roomCollaborationFactor / 100) * 5;
};

export const getRoomsNeeded = (
  totalOccupiedDesks: number,
  privateOffices: number,
  avgMeetingsPerDay: number,
  avgMeetingSize: number
) => {
  return (
    ((totalOccupiedDesks - privateOffices * 2) * avgMeetingsPerDay) /
    avgMeetingSize /
    8
  ); // assume 6 hours a day of prime-time meeting time
};
