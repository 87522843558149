import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Input } from 'antd';
import { mdiContentCopy } from '@mdi/js';
import Icon from '@mdi/react';
import styled from '@emotion/styled';
import { nth } from '../utils/math';
import { getBaseUrl } from '../utils/url';
import { track } from '@amplitude/analytics-browser';

interface ShareProps {
  collabScore: number;
  occupancyScore: number;
  adhocCollabScore: number;
  plannedCollabScore: number;
  shortCode: string;
}

const FlexContainer = styled.div`
  display: flex;
  @media (max-width: 600px), (max-width: 880px) and (min-width: 748px) {
    flex-direction: column;
    > div {
      max-width: 100% !important;
    }
    div:nth-of-type(2) h4 {
      margin-top: 20px;
    }
  }
  h4 {
    margin-top: 0;
    font-weight: 600;
    color: #ffffffdd;
  }
  > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 50%;
  }
`;

const CopyInput = styled(Input)`
  width: 100%;
  cursor: pointer;
  input {
    cursor: pointer;
    border: none !important;
    border-right: 1px solid #d9d9d9 !important;
    box-shadow: none !important;
  }
  span.ant-input-group-addon {
    background-color: #fff;
    margin: 0;
    padding: 0;
  }
`;

const Preview = styled.pre`
  font-family: 'Roboto', sans-serif;
  background: #00000055;
  padding: 10px;
  display: inline-block;
  border: 1px solid #00000099;
  border-radius: 5px;
  margin: 0 0 10px 0;
  overflow-x: hidden;
`;

const ShareButton: React.FC<ShareProps> = ({
  collabScore,
  occupancyScore,
  adhocCollabScore,
  plannedCollabScore,
  shortCode,
}) => {
  const [isFunCopied, setIsFunCopied] = React.useState(false);
  const [isHumDrumCopied, setIsHumDrumCopied] = React.useState(false);

  const shareUrl = `${getBaseUrl(true)}/r/${shortCode}`;

  // take occupancy score (1-100) and turn it into a string of emojis
  // for every 20 (rounded up) points, add a new green square emoji
  // and then fill in the remainder with grey square emojis so that its
  // always 5 emojis long. Do this for each of the three scores
  const occupancyEmojis = [];
  const adhocEmojis = [];
  const plannedEmojis = [];
  for (let i = 0; i < 5; i++) {
    if (i < Math.ceil(occupancyScore / 20)) {
      occupancyScore < 40
        ? occupancyEmojis.push('🟨')
        : occupancyEmojis.push('🟩');
    } else {
      occupancyEmojis.push('⬜');
    }
    if (i < Math.ceil(plannedCollabScore / 20)) {
      plannedCollabScore < 40
        ? plannedEmojis.push('🟨')
        : plannedEmojis.push('🟩');
    } else {
      plannedEmojis.push('⬜');
    }
    if (i < Math.ceil(adhocCollabScore / 20)) {
      adhocCollabScore < 40 ? adhocEmojis.push('🟨') : adhocEmojis.push('🟩');
    } else {
      adhocEmojis.push('⬜');
    }
  }

  // use gold, silver, or bronze medal emojis based on the total score
  let medalEmoji = '🥉';
  if (collabScore >= 50) {
    medalEmoji = '🥈';
  }
  if (collabScore >= 80) {
    medalEmoji = '🥇';
  }

  const emojiMessage = `CollabScore
🏢 ${occupancyEmojis.join('')}
📅 ${plannedEmojis.join('')}
💬 ${adhocEmojis.join('')}
${medalEmoji} ${collabScore + nth(collabScore)} percentile

${shareUrl}
`;

  return (
    <FlexContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: 10,
        }}
      >
        <h4>The Fun Way</h4>
        <Preview>{emojiMessage}</Preview>
        <CopyToClipboard
          text={emojiMessage}
          onCopy={() => {
            track('copied-share-url', { url: shareUrl, type: 'emojis' });
            setIsFunCopied(true);
            setTimeout(() => setIsFunCopied(false), 3000);
          }}
        >
          <Button icon={<Icon path={mdiContentCopy} size={0.5} />}>
            {isFunCopied ? 'Copied to clipboard 👍' : 'Copy'}
          </Button>
        </CopyToClipboard>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: 10,
        }}
      >
        <h4>The Humdrum Way</h4>
        <CopyToClipboard text={shareUrl}>
          <CopyInput
            styles={{
              input: { fontSize: '11px', height: '36px' },
            }}
            readOnly={true}
            addonAfter={
              <CopyToClipboard
                text={shareUrl}
                onCopy={() => {
                  track('copied-share-url', { url: shareUrl, type: 'url' });
                  setIsHumDrumCopied(true);
                  setTimeout(() => setIsHumDrumCopied(false), 3000);
                }}
              >
                <Button
                  type="text"
                  style={{
                    border: 'none',
                    padding: 0,
                    width: '32px',
                    height: '34px',
                    borderRadius: 0,
                  }}
                >
                  {isHumDrumCopied ? (
                    '👍'
                  ) : (
                    <Icon path={mdiContentCopy} size={0.5} />
                  )}
                </Button>
              </CopyToClipboard>
            }
            value={shareUrl}
          />
        </CopyToClipboard>
      </div>
    </FlexContainer>
  );
};

export default ShareButton;
