import styled from '@emotion/styled';

const MapArea = styled.div`
  padding: 0;
  border: 1px solid black;
  background-color: #f0f0f0;
  position: relative;
  width: fit-content;
  transform-style: preserve-3d;
`;

export const LeftVerticalWall = styled.div`
  width: 30px;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transform: rotateY(90deg) translateZ(-15px) translateX(-15px);
  border-left: 1px solid black;
`;
export const TopVerticalWall = styled.div`
  height: 30px;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  right: 0;
  transform: rotateX(90deg) translateZ(15px) translateY(15px);
  left: 0;
  border-bottom: 1px solid black;
`;

const RoomInnerComponent = styled.div`
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  border: 1px solid #ccc;
`;

export const Room = ({ ...props }) => {
  const combinedClassName = `room ${props.className || ''}`.trim();
  return <RoomInnerComponent className={combinedClassName} {...props} />;
};

export const CallBooth = styled(Room)`
  width: 15px;
  height: 15px;
  margin: 2px;
  border-radius: 3px;
  border-color: #aaa;
  background-color: #ddd;
`;

export const Pod = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: #f0f0f0;
  width: fit-content;
`;

export const PodVertical = styled(Pod)`
  grid-template-columns: repeat(2, 1fr);
  align-content: start;
`;

export default MapArea;
