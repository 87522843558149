import React, { useEffect } from 'react';
import Desk from './Desk';
import { useDeskOccupancy, useRoomColors } from '../hooks/mapAreaHooks';
import MapArea, {
  Room,
  LeftVerticalWall,
  Pod,
  PodVertical,
  TopVerticalWall,
  CallBooth,
} from './MapArea';
import styled from '@emotion/styled';
import { MapAreaProps } from '../interfaces/mapAreas';
import { useElementSize } from '../hooks/resizeObserver';
import { useSizes } from '../contexts/SizeContext';

const AreaGrid = styled(MapArea)`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto 1fr auto auto;
`;

const LeftWall = styled.div`
  grid-column: 1 / 2; /* Span the first column */
  grid-row: 1 / 4; /* Span all three rows */
  padding-bottom: 0;
`;

const TopWall = styled.div`
  grid-column: 2 / 3; /* Located in the second column */
  grid-row: 1; /* First row */
  display: flex;
  flex-direction: row;
`;

const DeskAreaTop = styled.div`
  grid-column: 3 / 4;
  grid-row: 1;
  position: relative;
  display: grid;
  margin: auto;
  z-index: 1;
`;

const DeskAreaMain = styled.div`
  grid-column: 2 / 4; /* Located in the second column */
  grid-row: 2; /* Second row */
  position: relative;
  display: grid;
  margin: auto;
  z-index: 1;
`;

const BottomWall = styled.div`
  grid-column: 2 / 5; /* Located in the second column */
  grid-row: 3; /* Third row */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const RightWall = styled.div`
  grid-column: 4 / 5;
  grid-row: 1 / 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MapArea1: React.FC<MapAreaProps> = ({
  multiplier,
  desks,
  rooms,
  privateOffices,
  callBooths,
  occupancy,
  currentDay,
  roomOccupancyRate,
  deskCollaborationFactor,
  showDetails = true,
}) => {
  const roomsLeftWall = Math.min(rooms - 1, 3);
  const roomsBottomWall = Math.min(rooms - 1 - roomsLeftWall, 4);
  const roomsTopWall = Math.min(rooms - 1 - roomsLeftWall - roomsBottomWall, 5);
  const callBoothsLeftWall = Math.min(callBooths, 3);
  const callBoothsRightWall = Math.min(callBooths - callBoothsLeftWall, 6);
  const callBoothsBottomWall = Math.min(
    callBooths - callBoothsLeftWall - callBoothsRightWall,
    4
  );

  const { ref: areaGrid, size } = useElementSize<HTMLDivElement>();
  const { setSizes } = useSizes();

  useEffect(() => {
    setSizes((prevSizes) => ({ ...prevSizes, mapArea1: size }));
  }, [size, setSizes]);

  let desksMain = 0;
  let podsMain;
  let desksTop = 0;
  let podsTop = 0;
  let desksMainOverflow = 0;
  let podsMainOverflow = 0;

  if (desks <= 72) {
    desksMain = desks;
    podsMain = Math.ceil(desksMain / 6);
  } else if (desks > 72 && desks <= 102) {
    desksMain = 72;
    podsMain = Math.ceil(desksMain / 6);
    desksTop = desks - desksMain;
    podsTop = Math.ceil(desksTop / 6);
  } else {
    desksMain = 72;
    podsMain = Math.ceil(desksMain / 6);
    desksTop = 30;
    podsTop = Math.ceil(desksTop / 6);
    desksMainOverflow = desks - desksMain - desksTop;
    podsMainOverflow = Math.ceil(desksMainOverflow / 6);
  }
  const sideRoomWidth = 60 * multiplier;
  const sideRoomHeight = 50 * multiplier;
  const topBottomRoomWidth = 50 * multiplier;
  const topBottomRoomHeight = 60 * multiplier;
  const cornerRoomWidth = 60 * multiplier;
  const cornerRoomHeight = 80 * multiplier;

  const callBoothWidth = 24 * multiplier;
  const callBoothHeight = 24 * multiplier;

  const sideRoomStyle: React.CSSProperties = {
    width: `${sideRoomWidth}px`,
    height: `${sideRoomHeight}px`,
  };
  const topBottomRoomStyle: React.CSSProperties = {
    width: `${topBottomRoomWidth}px`,
    height: `${topBottomRoomHeight}px`,
  };
  const cornerRoomStyle: React.CSSProperties = {
    width: `${cornerRoomWidth}px`,
    height: `${cornerRoomHeight}px`,
  };
  const callBoothStyle: React.CSSProperties = {
    width: `${callBoothWidth}px`,
    height: `${callBoothHeight}px`,
    margin: `${2 * multiplier}px`,
    borderRadius: `${3 * multiplier}px`,
  };

  const podGap = 2 * multiplier;

  const deskAreaStyle: React.CSSProperties = {
    padding: 30 * multiplier + 'px',
    gap: 15 * multiplier + 'px',
    ...getDeskAreaGridOptions(podsMain + podsMainOverflow),
  };

  const deskAreaTopStyle: React.CSSProperties = {
    paddingTop: `0`,
    paddingRight: 30 * multiplier + 'px',
    paddingLeft: 30 * multiplier + 'px',
    paddingBottom: 10 * multiplier + 'px',
    gap: 15 * multiplier + 'px',
    gridTemplateColumns: `repeat(${podsTop}, 1fr)`,
  };

  const occupiedDesks = useDeskOccupancy(
    desks,
    occupancy[currentDay] / 100,
    currentDay,
    deskCollaborationFactor,
    showDetails
  );
  const roomColors = useRoomColors(
    showDetails,
    roomOccupancyRate,
    rooms,
    privateOffices
  );

  return (
    <AreaGrid ref={areaGrid}>
      <LeftVerticalWall
        style={{
          width: 30 * multiplier + 'px',
          transform: `rotateY(90deg) translateZ(-${
            15 * multiplier
          }px) translateX(-${15 * multiplier}px)`,
        }}
      />
      <TopVerticalWall
        style={{
          height: 30 * multiplier + 'px',
          transform: `rotateX(90deg) translateZ(${
            15 * multiplier
          }px) translateY(${15 * multiplier}px)`,
        }}
      />
      <LeftWall>
        {rooms > 0 && (
          <Room
            style={{
              ...cornerRoomStyle,
              ...roomColors[0],
            }}
          />
        )}
        {Array.from({ length: roomsLeftWall }, (_, roomIndex) => (
          <Room
            key={`left-${roomIndex}`}
            style={{
              ...sideRoomStyle,
              ...roomColors[roomIndex + 1],
            }}
          />
        ))}
        {Array.from({ length: callBoothsLeftWall }, (_, roomIndex) => (
          <CallBooth key={`left-${roomIndex}`} style={callBoothStyle} />
        ))}
      </LeftWall>
      <TopWall>
        {Array.from({ length: roomsTopWall }, (_, roomIndex) => (
          <Room
            key={`top-${roomIndex}`}
            style={{
              ...topBottomRoomStyle,
              ...roomColors[roomIndex + roomsLeftWall + roomsBottomWall + 1],
            }}
          />
        ))}
      </TopWall>
      <DeskAreaTop style={deskAreaTopStyle}>
        {Array.from({ length: podsTop }, (_, podIndex) => (
          <PodVertical key={podIndex} style={{ gap: podGap + 'px' }}>
            {Array.from(
              {
                length: podIndex < Math.floor(desksTop / 6) ? 6 : desksTop % 6,
              },
              (_, deskIndex) => {
                const absoluteDeskIndex = desksMain + podIndex * 6 + deskIndex;
                return (
                  <Desk
                    key={deskIndex}
                    isVertical={true}
                    sizeMultiplier={multiplier}
                    isOccupied={occupiedDesks[absoluteDeskIndex]}
                  />
                );
              }
            )}
          </PodVertical>
        ))}
      </DeskAreaTop>
      <DeskAreaMain style={deskAreaStyle}>
        {Array.from({ length: podsMain }, (_, podIndex) => (
          <Pod key={podIndex} style={{ gap: podGap + 'px' }}>
            {Array.from(
              {
                length:
                  podIndex < Math.floor(desksMain / 6) ? 6 : desksMain % 6,
              },
              (_, deskIndex) => {
                const absoluteDeskIndex = podIndex * 6 + deskIndex; // Calculate the absolute index of the desk
                return (
                  <Desk
                    key={deskIndex}
                    isVertical={false}
                    sizeMultiplier={multiplier}
                    isOccupied={occupiedDesks[absoluteDeskIndex]}
                  />
                );
              }
            )}
          </Pod>
        ))}
        {Array.from({ length: podsMainOverflow }, (_, podIndex) => (
          <Pod key={podIndex + 'overflow'} style={{ gap: podGap + 'px' }}>
            {Array.from(
              {
                length:
                  podIndex < Math.floor(desksMainOverflow / 6)
                    ? 6
                    : desksMainOverflow % 6,
              },
              (_, deskIndex) => {
                const absoluteDeskIndex =
                  podIndex * 6 + deskIndex + desksMain + desksTop; // Calculate the absolute index of the desk
                return (
                  <Desk
                    key={deskIndex}
                    isVertical={false}
                    sizeMultiplier={multiplier}
                    isOccupied={occupiedDesks[absoluteDeskIndex]}
                  />
                );
              }
            )}
          </Pod>
        ))}
      </DeskAreaMain>
      <BottomWall>
        {Array.from({ length: callBoothsBottomWall }, (_, roomIndex) => (
          <CallBooth
            style={{
              ...callBoothStyle,
              marginTop: topBottomRoomHeight - callBoothHeight - 5 + 'px',
            }}
            key={`bottom-${roomIndex}`}
          />
        ))}
        {Array.from({ length: roomsBottomWall }, (_, roomIndex) => (
          <Room
            key={`bottom-${roomIndex}`}
            style={{
              ...topBottomRoomStyle,
              ...roomColors[roomIndex + roomsLeftWall + 1],
            }}
          />
        ))}
      </BottomWall>
      <RightWall>
        {Array.from({ length: callBoothsRightWall }, (_, roomIndex) => (
          <CallBooth key={`top-${roomIndex}`} style={callBoothStyle} />
        ))}
      </RightWall>
    </AreaGrid>
  );
};

function getDeskAreaGridOptions(pods: number) {
  if (pods <= 12) {
    let cols, rows;
    if (pods <= 2) {
      cols = pods;
      rows = 1;
    } else if (pods <= 4) {
      cols = 2;
      rows = 2;
    } else if (pods <= 6) {
      cols = 3;
      rows = 2;
    } else if (pods <= 9) {
      cols = 3;
      rows = 3;
    } else {
      cols = 4;
      rows = 3;
    }

    return {
      gridTemplateColumns: `repeat(${cols}, 1fr)`,
      gridTemplateRows: `repeat(${rows}, auto)`,
      gridAutoFlow: 'column',
    };
  } else {
    const cols = Math.floor(pods / 4);
    return {
      gridTemplateColumns: `repeat(${cols}, 1fr)`,
      gridTemplateRows: `repeat(4, auto)`,
      gridAutoFlow: 'column',
    };
  }
}

export default MapArea1;
