import React from 'react';
import styled from '@emotion/styled';
import { commonColors } from '../utils/colors';
import { Button } from 'antd';
import { CalculationResult } from '../utils/calculate';
import ScoreSheet from './ScoreSheet';
import ShareButton from './ShareButton';
import Icon from '@mdi/react';
import { mdiClose, mdiExportVariant, mdiOpenInNew } from '@mdi/js';
import { ControlValues } from './Controls';
import { useControlContext } from '../App';
import { Result as ResultType } from '../interfaces/types';
import { track } from '@amplitude/analytics-browser';
import { getBaseUrl } from '../utils/url';

const ResultContents = styled.div`
  background: ${commonColors.darkPurpleSurfaceDarker}cc;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  border-radius: 6px;
`;

const ActualContents = styled.div`
  background: transparent;
  color: white;
  padding: 20px;
  border-radius: 6px;
  background: ${commonColors.darkPurpleSurfaceDarker};
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  &.slide-in {
    animation: slide-in 0.2s forwards;
  }
  &.slide-out {
    animation: slide-out 0.1s forwards;
  }
  p {
    color: white;
    font-size: 12px;
  }
  @media (max-width: 500px) {
    p {
      font-size: 10px;
    }
    padding: 10px;
  }

  @keyframes slide-in {
    from {
      transform: translateY(20%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 100;
    }
  }
  @keyframes slide-out {
    from {
      transform: translateY(0);
      opacity: 100;
    }
    to {
      transform: translateY(20%);
      opacity: 0;
    }
  }
`;

const AnimatedEllipsis = styled.span`
  &::after {
    display: inline-block;
    content: '';
    animation: ellipsis 1.8s infinite;
    color: #ffffff88;
    margin-left: 1px;
  }

  @keyframes ellipsis {
    0% {
      content: '';
    }
    25% {
      content: '.';
    }
    50% {
      content: '..';
    }
    75% {
      content: '...';
    }
  }
`;

const ShowShareButton = styled(Button)`
  background: ${commonColors.darkPurpleSurface};
  border-color: ${commonColors.darkPurpleSurface};
  color: white;
  &:hover {
    background: ${commonColors.logo2} !important;
    border-color: ${commonColors.logo2} !important;
    color: white !important;
  }
`;

const FullAnalysisButton = styled(Button)`
  &:hover {
    border-color: ${commonColors.logo2} !important;
    color: ${commonColors.logo2} !important;
  }
`;

export interface ResultProps {
  controlValues: ControlValues;
  isCalculating?: boolean;
  // controlValues: ControlValues | null;
  result: ResultType | null;
}

const Result: React.FC<ResultProps> = ({
  controlValues,
  isCalculating,
  result,
}) => {
  const [isSharing, setIsSharing] = React.useState(false);

  const adhocCollabScore = Math.floor(
    (result?.result.adhocCollabScore ?? 0) * 100
  );
  const occupancyScore = Math.floor((result?.result.occupancyScore ?? 0) * 100);
  const plannedCollabScore = Math.floor(
    (result?.result.plannedCollabScore ?? 0) * 100
  );
  const collabScore = Math.floor((result?.result.collabScore ?? 0) * 100);

  const controlContext = useControlContext();

  React.useEffect(() => {
    if (isCalculating && isSharing) {
      setIsSharing(false);
    }
  }, [isCalculating, isSharing]);

  return (
    <ResultContents>
      <ActualContents
        style={{ height: !isCalculating && result ? '100%' : 'auto' }}
      >
        <Button
          shape={'circle'}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: commonColors.darkPurpleSurfaceLighter,
          }}
          type={'primary'}
          icon={<Icon path={mdiClose} size={0.5} />}
          onClick={() => {
            controlContext?.setShowResult(false);
            controlContext?.setIsCalculating(false);
          }}
        ></Button>

        {isCalculating && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 300,
              }}
            >
              <h3>
                Simulating the last 90 days
                <AnimatedEllipsis />
              </h3>
              <p
                style={{
                  color: '#ffffffcc',
                  fontSize: '13px',
                  marginTop: '5px',
                }}
              >
                Applying your parameters to authentic work models and scenarios.
                This may take a moment.
              </p>
            </div>
          </>
        )}
        {!isCalculating && result && (
          <div
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <div style={{ flex: 1, marginTop: '20px' }}>
              <ScoreSheet
                adhocCollabScore={adhocCollabScore}
                occupancyScore={occupancyScore}
                plannedCollabScore={plannedCollabScore}
                collabScore={collabScore}
                summaries={result.summaries}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap', // Allow flex items to wrap
                maxWidth: '450px',
                margin: '20px auto',
              }}
            >
              {result.shortCode && (
                <ShowShareButton
                  icon={<Icon path={mdiExportVariant} size={0.5} />}
                  onClick={() => {
                    setIsSharing(true);
                    track('opened-sharing');
                  }}
                >
                  Share
                </ShowShareButton>
              )}
              <FullAnalysisButton
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  // open a URL
                  window.open(
                    generateReportURL(
                      result?.result,
                      controlValues,
                      result?.shortCode
                    ),
                    '_blank'
                  );
                }}
                icon={<Icon path={mdiOpenInNew} size={0.5} />}
              >
                Read full analysis
              </FullAnalysisButton>
            </div>
          </div>
        )}
        {isSharing && result?.shortCode && (
          <ActualContents
            className="slide-in"
            style={{
              boxShadow: '0 0 100px #00000088',
              background: commonColors.darkPurpleSurface,
              borderTop: '1px solid #ffffff33',
              borderRadius: 0,
            }}
          >
            <Button
              shape={'circle'}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                backgroundColor: commonColors.darkPurpleSurfaceLighter,
              }}
              type={'primary'}
              icon={<Icon path={mdiClose} size={0.5} />}
              onClick={(event) => {
                const shareSheet = event.currentTarget.parentElement;
                if (shareSheet) {
                  shareSheet.classList.remove('slide-in');
                  shareSheet.classList.add('slide-out');
                  shareSheet.addEventListener(
                    'animationend',
                    () => setIsSharing(false),
                    { once: true }
                  );
                }
              }}
            ></Button>
            <ShareButton
              adhocCollabScore={adhocCollabScore}
              occupancyScore={occupancyScore}
              plannedCollabScore={plannedCollabScore}
              collabScore={collabScore}
              shortCode={result.shortCode}
            />
          </ActualContents>
        )}
      </ActualContents>
    </ResultContents>
  );
};

export default Result;

const generateReportURL = (
  result: CalculationResult,
  controlValues: ControlValues,
  shortCode?: string
) => {
  if (!result) return '';

  const averageOccupancy =
    Object.values(controlValues.culture.occupancy)
      .map((occupancy) => occupancy)
      .reduce((acc, curr) => acc + curr, 0) / 5;

  const peakOccupancy = Math.max(
    ...Object.values(controlValues.culture.occupancy)
  );

  const queryParams = new URLSearchParams({
    floors: controlValues.office.floors.toString(),
    desks: controlValues.office.desks.toString(),
    rooms: controlValues.office.rooms.toString(),
    privateOffices: controlValues.office.privateOffices.toString(),
    callBooths: controlValues.office.callBooths.toString(),
    policyType: controlValues.culture.policy.policyType.toString(),
    occupancyMonday: controlValues.culture.occupancy.monday.toString(),
    occupancyTuesday: controlValues.culture.occupancy.tuesday.toString(),
    occupancyWednesday: controlValues.culture.occupancy.wednesday.toString(),
    occupancyThursday: controlValues.culture.occupancy.thursday.toString(),
    occupancyFriday: controlValues.culture.occupancy.friday.toString(),

    occupancyScore: Math.floor(result.occupancyScore * 100).toString(),
    plannedCollabScore: Math.floor(result.plannedCollabScore * 100).toString(),
    adhocCollabScore: Math.floor(result.adhocCollabScore * 100).toString(),
    collabScore: Math.floor(result.collabScore * 100).toString(),

    averageOccupancy: averageOccupancy.toString(),
    peakOccupancy: peakOccupancy.toString(),
  });

  if (controlValues.culture.policy.policyType === 'flexible') {
    queryParams.set(
      'policyDays',
      (controlValues.culture.policy.policyDays ?? 0).toString()
    );
    queryParams.set(
      'policyFrequency',
      controlValues.culture.policy.policyFrequency ?? 'week'
    );
  }

  if (shortCode) {
    queryParams.set('shareUrl', `${getBaseUrl(true)}/r/${shortCode}`);
  }

  return `https://robinpowered.com/collabscore/results?redirect=true&${queryParams.toString()}`;
};
