import React from 'react';
import { commonColors } from '../utils/colors';

interface DeskProps {
  isVertical: boolean;
  sizeMultiplier: number;
  isOccupied?: boolean;
}

const Desk: React.FC<DeskProps> = ({
  isVertical,
  sizeMultiplier,
  isOccupied,
}) => {
  const deskWidth = (isVertical ? 8 : 24) * sizeMultiplier;
  const deskHeight = (isVertical ? 24 : 8) * sizeMultiplier;

  // if isOccupied, make hte desk grey, otherwise, green.
  const deskColor = isOccupied
    ? commonColors.deskPurple
    : commonColors.deskGrey;

  return (
    <div
      style={{
        width: deskWidth + 'px',
        height: deskHeight + 'px',
        backgroundColor: deskColor,
        zIndex: 1,
        borderRadius: '1px',
      }}
    ></div>
  );
};

export default Desk;
