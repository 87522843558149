/* eslint-disable max-len */
import { CommonIconProps } from './common';

export const RoomCollaborationFactorHigh = ({ size = 24 }: CommonIconProps) => (
  <svg
    width={typeof size === 'number' ? size : size.width}
    height={typeof size === 'number' ? size : size.height}
    fill="currentColor"
    viewBox="0 0 50 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M42.772 13.243h-2.418a4.662 4.662 0 0 0-1.878-3.231v-.757A4.26 4.26 0 0 0 34.219 5H32.37a4.26 4.26 0 0 0-4.257 4.254v3.25c-.086-.304-.24-1.228-.693-1.71l-.753-.684C25.25 9.073 21.72 8.583 19 10.041l-.977.65c-.8.905-.51.444-.97 1.813v-3.25A4.261 4.261 0 0 0 12.794 5h-1.848A4.26 4.26 0 0 0 6.69 9.254v.76a4.66 4.66 0 0 0-1.877 3.23H1.228A1.23 1.23 0 0 0 0 14.47v13.572a1.122 1.122 0 0 0 2.242 0v-9.79h2.54v3.695c0 .619.502 1.121 1.121 1.121h.787v.6a1.119 1.119 0 1 0 0 2.239v4.002a1.122 1.122 0 0 0 2.242 0v-4h3.112v4a1.121 1.121 0 0 0 2.242 0v-4.002a1.12 1.12 0 0 0 0-2.238v-.601h.787c.619 0 1.122-.502 1.122-1.121v-3.695h.682v3.695c0 .62.502 1.121 1.122 1.121h.786v.6a1.119 1.119 0 1 0 0 2.239v4.002a1.121 1.121 0 0 0 2.242 0v-4h3.11v4a1.123 1.123 0 0 0 2.245 0v-4.002a1.12 1.12 0 0 0 0-2.238v-.601h.786a1.12 1.12 0 0 0 1.121-1.121v-3.695h.684v3.695a1.12 1.12 0 0 0 1.12 1.121h.787v.6a1.119 1.119 0 1 0 0 2.239v4.002a1.122 1.122 0 0 0 2.243 0v-4h3.11v4a1.122 1.122 0 0 0 2.243 0v-4.002a1.12 1.12 0 1 0 0-2.238v-.601h.787c.62 0 1.122-.502 1.122-1.121v-3.695h1.373v9.79a1.121 1.121 0 0 0 2.242 0V14.47a1.23 1.23 0 0 0-1.228-1.228Zm-6.538 9.825v.6h-3.111v-.6h3.11Zm1.907-2.24h-6.926v-7.06a2.422 2.422 0 0 1 2.42-2.417h2.087a2.422 2.422 0 0 1 2.42 2.417v7.06ZM34.22 7.242a2.014 2.014 0 0 1 2.009 1.895 4.706 4.706 0 0 0-.506-.027h-2.087a4.649 4.649 0 0 0-3.28 1.35V9.254c0-1.11.904-2.012 2.015-2.012h1.849Zm-18.025 8.242h.683v.528h-.683v-.529.001Zm12.095 0h.684v.528h-.684v-.529.001Zm-13.48-6.23v1.206a4.654 4.654 0 0 0-3.279-1.351H9.445c-.172 0-.341.01-.507.028a2.015 2.015 0 0 1 2.01-1.896h1.848c1.11 0 2.014.903 2.014 2.012Zm-12.568 6.23h2.54v.529h-2.54v-.529Zm9.802 7.584v.6H8.932v-.6h3.112Zm1.907-2.24H7.024v-7.06a2.422 2.422 0 0 1 2.42-2.417h2.087a2.422 2.422 0 0 1 2.42 2.417v7.06Zm10.187 2.24v.6h-3.111v-.6h3.11Zm1.909-2.24h-6.928v-7.06a2.422 2.422 0 0 1 2.42-2.417h2.088a2.422 2.422 0 0 1 2.42 2.417v7.06Zm14.338-5.344h1.373v.529h-1.373v-.529Z"
    />
    <circle
      cx="22.5"
      cy="7.5"
      r="2.25"
      stroke="currentColor"
      fill="none"
      strokeWidth={'1.5'}
    />
    <circle
      cx="11.5"
      cy="3.5"
      r="2.25"
      stroke="currentColor"
      fill="none"
      strokeWidth={'1.5'}
    />
    <circle
      cx="33.5"
      cy="3.5"
      r="2.25"
      stroke="currentColor"
      fill="none"
      strokeWidth={'1.5'}
    />
  </svg>
);

export default RoomCollaborationFactorHigh;
