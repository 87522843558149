import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import DraggableSlider from './DraggableSlider';
import { Day, InputType } from '../interfaces/types';
import { trackInputChanged } from '../services/amplitude';

const ControlsWrapper = styled.div``;

const DayLabel = styled.label`
  text-transform: capitalize;
  font-size: 12px;
  color: #666;
  margin-top: 6px;
  margin-bottom: 0;
  width: 100%;
`;

const DayPercent = styled.span`
  font-size: 12px;
  color: #999;
  padding-left: 7px;
  margin-top: 6px;
  width: 100%;
  text-align: right;
`;

export interface OccupancyControlValues {
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
}

interface PolicyControlProps {
  values: OccupancyControlValues;
  onUpdated: (values: OccupancyControlValues) => void;
  onDayChange: (day: Day) => void;
}

const Controls: React.FC<PolicyControlProps> = ({
  values,
  onUpdated,
  onDayChange,
}) => {
  const setDay = useCallback(
    (value: number, inputType: InputType, day: Day) => {
      onUpdated({
        ...values,
        [day]: value,
      });
      onDayChange(day);
      trackInputChanged('occupancy.' + day, inputType, value);
    },
    [onUpdated, onDayChange, values]
  );

  return (
    <ControlsWrapper>
      <label>What is your average occupancy?</label>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '40px 1fr 42px',
          marginTop: '8px',
        }}
      >
        <DayLabel>Mon</DayLabel>
        <DraggableSlider
          value={values.monday}
          onChange={(value, inputType) => setDay(value, inputType, 'monday')}
          min={0}
          max={100}
          valueSuffix={'%'}
          style={{ margin: '4px 0' }}
          colorFullBar={false}
          showTooltip={false}
        />
        <DayPercent>{values.monday}%</DayPercent>
        <DayLabel>Tue</DayLabel>
        <DraggableSlider
          value={values.tuesday}
          onChange={(value, inputType) => setDay(value, inputType, 'tuesday')}
          min={0}
          max={100}
          valueSuffix={'%'}
          style={{ margin: '4px 0' }}
          colorFullBar={false}
          showTooltip={false}
        />
        <DayPercent>{values.tuesday}%</DayPercent>
        <DayLabel>Wed</DayLabel>
        <DraggableSlider
          value={values.wednesday}
          onChange={(value, inputType) => setDay(value, inputType, 'wednesday')}
          min={0}
          max={100}
          valueSuffix={'%'}
          style={{ margin: '4px 0' }}
          colorFullBar={false}
          showTooltip={false}
        />
        <DayPercent>{values.wednesday}%</DayPercent>
        <DayLabel>Thu</DayLabel>
        <DraggableSlider
          value={values.thursday}
          onChange={(value, inputType) => setDay(value, inputType, 'thursday')}
          min={0}
          max={100}
          valueSuffix={'%'}
          style={{ margin: '4px 0' }}
          colorFullBar={false}
          showTooltip={false}
        />
        <DayPercent>{values.thursday}%</DayPercent>
        <DayLabel>Fri</DayLabel>
        <DraggableSlider
          value={values.friday}
          onChange={(value, inputType) => setDay(value, inputType, 'friday')}
          min={0}
          max={100}
          valueSuffix={'%'}
          style={{ margin: '4px 0 15px 0' }}
          colorFullBar={false}
          showTooltip={false}
        />
        <DayPercent>{values.friday}%</DayPercent>
      </div>
    </ControlsWrapper>
  );
};

export default Controls;
