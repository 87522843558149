import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import DraggableSlider, {
  GreenBlueGradientStops,
  GreenYellowGradientStops,
} from './DraggableSlider';
import { Button, InputNumber, SliderSingleProps, Switch } from 'antd';
import Icon from '@mdi/react';
import {
  mdiArrowDownRightBold,
  mdiCreation,
  mdiMenuDown,
  mdiMenuUp,
  mdiOpenInNew,
} from '@mdi/js';
import DeskProximityLow from './icons/DeskProximityLow';
import DeskProximityMedium from './icons/DeskProximityMedium';
import DeskProximityHigh from './icons/DeskProximityHigh';
import RoomCollaborationFactorLow from './icons/RoomCollaborationFactorLow';
import RoomCollaborationFactorHigh from './icons/RoomCollaborationFactorHigh';
import PolicyControls, { PolicyControlValues } from './PolicyControls';
import OccupancyControls, { OccupancyControlValues } from './OccupancyControls';
import { commonColors } from '../utils/colors';
import { Day, InputType } from '../interfaces/types';
import { trackInputChanged } from '../services/amplitude';
import { track } from '@amplitude/analytics-browser';

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 260px;
`;

const ControlBox = styled.div<{ isOpen: boolean }>`
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  overflow: hidden;

  > div.controlBoxContent {
    max-height: ${({ isOpen }) =>
      isOpen
        ? '1000px'
        : '0px'}; /* Adjust max height according to your content */
    margin-top: ${({ isOpen }) => (isOpen ? '20px' : '0')};
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    transition: ${({ isOpen }) => (isOpen ? 'opacity .8s ease' : 'none')};
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: #666;
    padding: 10px;
  }
  h4 {
    cursor: pointer;
    margin: 0;
    -webkit-text-fill-color: transparent;
    color: transparent;
    &.greenYellow {
      background: ${({ isOpen }) =>
        isOpen ? '-webkit-linear-gradient(40deg, #049600, #d7ab00)' : '#888'};
      -webkit-background-clip: text;
      background-clip: text;
    }
    &.pinkOrange {
      background: ${({ isOpen }) =>
        isOpen
          ? `-webkit-linear-gradient(40deg, ${commonColors.controlsPink}, ${commonColors.controlsOrange})`
          : '#888'};
      -webkit-background-clip: text;
      background-clip: text;
    }
    &.bluePink {
      background: ${({ isOpen }) =>
        isOpen
          ? `-webkit-linear-gradient(40deg, ${commonColors.controlsBlue}, ${commonColors.controlsPink})`
          : '#888'};
      -webkit-background-clip: text;
      background-clip: text;
    }
    &.greenBlue {
      background: ${({ isOpen }) =>
        isOpen
          ? `-webkit-linear-gradient(40deg, ${commonColors.controlsGreen}, ${commonColors.controlsBlue})`
          : '#888'};
      -webkit-background-clip: text;
      background-clip: text;
    }
    &.rainbow {
      background: ${({ isOpen }) =>
        isOpen
          ? '-webkit-linear-gradient(40deg, #ff00b7, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82)'
          : '#888'};
      -webkit-background-clip: text;
      background-clip: text;
    }
  }
`;

const ContinueButton = styled(Button)`
  &,
  :hover {
    width: 100%;
    display: flex;
    position: relative;
    align-content: center;
    justify-content: center;
    text-align: center;
    color: #ffffffdd;
    background: #aaa;
    font-weight: 500;
    border: 1px solid #ffffff99;

    strong {
      font-weight: 800;
      margin-right: 5px;
    }

    &.greenYellow,
    &.bluePink,
    &.greenBlue,
    &.pinkOrange {
      border-radius: 10px;
      font-size: 12px;
      padding: 5px;
      min-height: 30px;

      :hover {
        color: #ffffff;
        text-shadow: 0 0 7px #ffffffaa;
      }
    }

    &.greenYellow {
      background: linear-gradient(
          40deg,
          ${commonColors.controlsGreenDarker},
          ${commonColors.controlsGreenYellow},
          ${commonColors.controlsYellowDarker}
        )
        200% 200% !important;
    }

    &.pinkOrange {
      background: linear-gradient(
          40deg,
          ${commonColors.controlsPink},
          ${commonColors.controlsOrange}
        )
        200% 200% !important;
    }

    &.bluePink {
      background: linear-gradient(
          40deg,
          ${commonColors.controlsBlue},
          ${commonColors.controlsPink}
        )
        200% 200% !important;
    }

    &.greenBlue {
      background: linear-gradient(
          40deg,
          ${commonColors.controlsGreen},
          ${commonColors.controlsBlue}
        )
        200% 200% !important;
    }

    &.rainbow {
      font-size: 14px;
      padding: 8px 0;
      min-height: 40px;
      border-radius: 30px;
      animation: hue-rotate 10s linear infinite;
      background: linear-gradient(
          60deg,
          #ff00b7,
          #ef4e7b,
          #a166ab,
          #5073b8,
          #1098ad,
          #07b39b,
          #6fba82
        )
        200% 200% !important;

      &:hover {
        text-shadow: 0 0 7px #ffffffaa;
      }
    }

    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 30px;
      z-index: -1;
      background-color: transparent;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 30px;
      z-index: -2;
      border: 1px solid #fff;
    }
  }

  @keyframes hue-rotate {
    from {
      -webkit-filter: hue-rotate(0);
      filter: hue-rotate(0);
    }
    to {
      -webkit-filter: hue-rotate(360deg);
      filter: hue-rotate(360deg);
    }
  }
`;

const GradientSwitch = styled(Switch)`
  &.blueGreen.ant-switch-checked {
    background: linear-gradient(
        40deg,
        ${commonColors.controlsGreen},
        ${commonColors.controlsBlue}
      )
      200% 200% !important;
  }
`;

const ExpandContractIcon: React.FC<{ isCurrent: boolean }> = ({
  isCurrent,
}) => (
  <Icon
    path={isCurrent ? mdiMenuUp : mdiMenuDown}
    size={'24px'}
    style={{
      marginTop: '-5px',
      marginRight: '-5px',
      float: 'right',
      color: '#00000033',
    }}
  />
);

export interface ControlValues {
  office: {
    floors: number;
    desks: number;
    rooms: number;
    privateOffices: number;
    callBooths: number;
  };
  culture: {
    policy: PolicyControlValues;
    occupancy: OccupancyControlValues;
    workingProximity: number;
    departmentsMix: boolean;
    roomCollaborationFactor: number;
    avgMeetingsPerDay: number;
  };
}
interface ControlsProps {
  values: ControlValues;
  onUpdated: (values: ControlValues) => void;
  onDayChange: (day: Day) => void;
  onSlideStarted?: (isLive: boolean) => void;
  onSlideEnded?: () => void;
  onSubmitted: (values: ControlValues) => void;
}

const Controls: React.FC<ControlsProps> = ({
  values,
  onUpdated,
  onDayChange,
  onSlideStarted,
  onSlideEnded,
  onSubmitted,
}) => {
  const [currentBox, setCurrentBox] = useState<number>(1);
  const controlBoxRefs = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    const currentRefs = controlBoxRefs.current;
    currentRefs.forEach((ref, index) => {
      if (ref) {
        ref.addEventListener('focusin', () => setCurrentBox(index + 1));
      }
    });

    return () => {
      currentRefs.forEach((ref) => {
        if (ref) {
          ref.removeEventListener('focusin', () => {});
        }
      });
    };
  }, []);

  const goToBox = (boxNumber: number) => {
    setCurrentBox(boxNumber);
    const mediaQuery = window.matchMedia('(max-width: 748px)');
    if (mediaQuery.matches) {
      window.scrollTo(0, (boxNumber - 1) * 79 + 45);
    }
  };

  const commonSliderProps = (isLive = false) => {
    return {
      onSlideStarted: onSlideStarted ? () => onSlideStarted(isLive) : undefined,
      onSlideEnded: onSlideEnded ? () => onSlideEnded() : undefined,
    };
  };

  const commonOfficeScaleProps = (isLive = false) => {
    return {
      ...commonSliderProps(isLive),
      showInput: true,
      gradientStops: GreenYellowGradientStops,
    };
  };

  const commonWorkplaceCultureProps = (isLive = false) => {
    return {
      ...commonSliderProps(isLive),
      gradientStops: GreenBlueGradientStops,
    };
  };

  const setFloors = useCallback(
    (value: number, inputType: InputType) => {
      onUpdated({ ...values, office: { ...values.office, floors: value } });
      trackInputChanged('floors', inputType, value);
    },
    [onUpdated, values]
  );

  const setDesks = useCallback(
    (value: number, inputType: InputType) => {
      onUpdated({ ...values, office: { ...values.office, desks: value } });
      trackInputChanged('desks', inputType, value);
    },
    [onUpdated, values]
  );

  const setRooms = useCallback(
    (value: number, inputType: InputType) => {
      onUpdated({ ...values, office: { ...values.office, rooms: value } });
      trackInputChanged('rooms', inputType, value);
    },
    [onUpdated, values]
  );

  const setPrivateOffices = useCallback(
    (value: number, inputType: InputType) => {
      onUpdated({
        ...values,
        office: { ...values.office, privateOffices: value },
      });
      trackInputChanged('private_offices', inputType, value);
    },
    [onUpdated, values]
  );

  const setCallBooths = useCallback(
    (value: number, inputType: InputType) => {
      onUpdated({
        ...values,
        office: { ...values.office, callBooths: value },
      });
      trackInputChanged('call_booths', inputType, value);
    },
    [onUpdated, values]
  );

  const setPolicy = useCallback(
    (value: PolicyControlValues) => {
      onUpdated({
        ...values,
        culture: { ...values.culture, policy: value },
      });
    },
    [onUpdated, values]
  );

  const setOccupancy = useCallback(
    (value: OccupancyControlValues) => {
      onUpdated({
        ...values,
        culture: { ...values.culture, occupancy: value },
      });
    },
    [onUpdated, values]
  );

  const setWorkingProximity = useCallback(
    (value: number, inputType: InputType) => {
      onUpdated({
        ...values,
        culture: { ...values.culture, workingProximity: value },
      });
      trackInputChanged('working_proximity', inputType, value);
    },
    [onUpdated, values]
  );

  const setDepartmentsMix = useCallback(
    (value: boolean, inputType: InputType) => {
      onUpdated({
        ...values,
        culture: { ...values.culture, departmentsMix: value },
      });
      trackInputChanged('departments_mix', inputType, value);
    },
    [onUpdated, values]
  );

  const setRoomCollaborationFactor = useCallback(
    (value: number, inputType: InputType) => {
      onUpdated({
        ...values,
        culture: { ...values.culture, roomCollaborationFactor: value },
      });
      trackInputChanged('room_collaboration_factor', inputType, value);
    },
    [onUpdated, values]
  );

  const deskProximitySliderMarks: SliderSingleProps['marks'] = {
    0: {
      style: {
        color: '#ccc',
        marginTop: '5px',
        transform: 'none',
      },
      label: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <DeskProximityLow occupiedColor={commonColors.deskPurple} />
          <label style={{ fontSize: '10px', color: '#9e9595' }}>
            Always alone
          </label>
        </div>
      ),
    },
    50: {
      style: {
        color: '#ccc',
        marginTop: '5px',
      },
      label: <DeskProximityMedium occupiedColor={commonColors.deskPurple} />,
    },
    100: {
      style: {
        color: '#ccc',
        marginTop: '5px',
        transform: 'none',
        right: 0,
        left: 'auto',
      },
      label: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
          }}
        >
          <DeskProximityHigh occupiedColor={commonColors.deskPurple} />
          <label style={{ fontSize: '10px', color: '#9e9595' }}>
            Always together
          </label>
        </div>
      ),
    },
  };

  const roomCollaborationSliderMarks: SliderSingleProps['marks'] = {
    0: {
      style: {
        color: '#ccc',
        marginTop: '5px',
        transform: 'none',
      },
      label: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <RoomCollaborationFactorLow size={{ width: 40, height: 30 }} />
          <label style={{ fontSize: '10px', color: '#9e9595' }}>
            Remote meetings
          </label>
        </div>
      ),
    },
    100: {
      style: {
        color: '#ccc',
        marginTop: '5px',
        transform: 'none',
        right: 0,
        left: 'auto',
      },
      label: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
          }}
        >
          <RoomCollaborationFactorHigh size={{ width: 40, height: 30 }} />
          <label style={{ fontSize: '10px', color: '#9e9595' }}>
            In-person collaboration
          </label>
        </div>
      ),
    },
  };

  return (
    <ControlsWrapper id={`controlWrapper`}>
      <ControlBox
        isOpen={currentBox === 1}
        ref={(ref) => (controlBoxRefs.current[0] = ref)}
      >
        <h4
          className={`greenYellow`}
          onClick={() =>
            currentBox === 1 ? goToBox(currentBox + 1) : goToBox(1)
          }
        >
          1. Office Size &amp; Resources
          <ExpandContractIcon isCurrent={currentBox === 1} />
        </h4>
        <div className={`controlBoxContent`}>
          <DraggableSlider
            {...commonOfficeScaleProps()}
            label="Floors"
            value={values.office.floors}
            onChange={setFloors}
            min={1}
            max={20}
            onSlideEnded={onSlideEnded}
          />
          <div className={`floorControls`}>
            <label>Approx. Resources Per floor</label>
            <DraggableSlider
              {...commonOfficeScaleProps()}
              label="Desks"
              value={values.office.desks}
              onChange={setDesks}
              min={6}
              max={500}
              onSlideEnded={onSlideEnded}
            />
            <DraggableSlider
              {...commonOfficeScaleProps()}
              label="Total Rooms"
              value={values.office.rooms}
              onChange={setRooms}
              min={0}
              max={50}
            />
            <div
              style={{
                marginTop: '-20px',
                marginBottom: '20px',
                paddingTop: '5px',
                display: 'grid',
                gridTemplateColumns: '1fr auto',
              }}
            >
              <label
                style={{
                  fontWeight: '400',
                  color: '#333',
                  fontSize: '12px',
                  display: 'block',
                  paddingTop: '3px',
                }}
              >
                How many are private offices?
              </label>
              <InputNumber
                controls={false}
                min={0}
                max={values.office.rooms}
                value={values.office.privateOffices}
                onChange={(value) => {
                  setPrivateOffices(
                    Math.max(0, Math.min(value ?? 0, values.office.rooms)),
                    'text'
                  );
                }}
                size="small"
                style={{ width: '50px', marginLeft: `15px`, height: `22px` }}
              />
            </div>
            <DraggableSlider
              {...commonOfficeScaleProps()}
              label="Call Booths"
              value={values.office.callBooths}
              onChange={setCallBooths}
              min={0}
              max={25}
            />

            <ContinueButton
              className={`greenYellow`}
              type={`primary`}
              onClick={() => goToBox(2)}
            >
              <strong>Step 2:</strong> Workplace Policy{' '}
            </ContinueButton>
          </div>
        </div>
      </ControlBox>
      <ControlBox
        isOpen={currentBox === 2}
        ref={(ref) => (controlBoxRefs.current[1] = ref)}
      >
        <h4
          className={`pinkOrange`}
          onClick={() =>
            currentBox === 2 ? goToBox(currentBox + 1) : goToBox(2)
          }
        >
          2. Workplace Policy
          <ExpandContractIcon isCurrent={currentBox === 2} />
        </h4>
        <div className={`controlBoxContent`}>
          <label>What is your in-office requirement?</label>

          <PolicyControls
            values={values.culture.policy}
            onUpdated={setPolicy}
          />

          <ContinueButton
            className={`pinkOrange`}
            type={`primary`}
            onClick={() => goToBox(3)}
          >
            <strong>Step 3:</strong> Workplace Culture
          </ContinueButton>
        </div>
      </ControlBox>
      <ControlBox
        isOpen={currentBox === 3}
        ref={(ref) => (controlBoxRefs.current[2] = ref)}
      >
        <h4
          className={`greenBlue`}
          onClick={() =>
            currentBox === 3 ? goToBox(currentBox + 1) : goToBox(3)
          }
        >
          3. Workplace Culture
          <ExpandContractIcon isCurrent={currentBox === 3} />
        </h4>
        <div className={`controlBoxContent`}>
          <DraggableSlider
            {...commonWorkplaceCultureProps()}
            label="Do people sit alone or together?"
            info={
              <>
                <p>
                  This slider helps us understand whether people in your office
                  mostly sit alone or together. If they mostly sit alone, slide
                  to the left. If they mostly sit together, slide to the right.
                </p>
                <p>
                  If you are unsure, sample the usage by walking around your
                  office for 15 minutes and observe how spread out or grouped up
                  people tend to be.
                </p>
              </>
            }
            value={values.culture.workingProximity}
            onChange={setWorkingProximity}
            marks={deskProximitySliderMarks}
            onSlideStarted={undefined}
            min={0}
            max={100}
            showValue={false}
            style={{ marginBottom: '60px' }}
            tooltipFormatter={(value) => {
              value = value ?? 0;
              return (
                <div>
                  {value < 30
                    ? 'People mostly sit alone'
                    : value < 50
                    ? 'People sometimes sit together '
                    : value < 80
                    ? 'People usually sit together'
                    : 'People always sit together'}
                  <span style={{ display: 'none' }}>{value}</span>
                </div>
              );
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '20px',
            }}
          >
            <label>Do different departments sit in the same areas?</label>
            <GradientSwitch
              className={`blueGreen`}
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={values.culture.departmentsMix}
              onChange={(checked) => {
                setDepartmentsMix(checked, 'checkbox');
              }}
              style={{
                marginBottom: '20px',
                minWidth: '60px',
                marginLeft: '10px',
              }}
            />
          </div>
          <DraggableSlider
            {...commonWorkplaceCultureProps()}
            label="How do people use meeting rooms?"
            info={
              <>
                <p>
                  This slider helps us understand how your meeting rooms are
                  used. If they are mostly used for remote calls, slide to the
                  left. If they are mostly used for in-person collaboration,
                  slide to the right.
                </p>
                <p>
                  If you are unsure, sample the usage by walking around your
                  office for 15 minutes and count how many conference rooms have
                  a solo occupant, how many have multiple occupants, and how
                  many are empty.
                </p>
              </>
            }
            value={values.culture.roomCollaborationFactor}
            onChange={setRoomCollaborationFactor}
            marks={roomCollaborationSliderMarks}
            onSlideStarted={undefined}
            tooltipFormatter={(value) => {
              value = value ?? 0;
              return (
                <div>
                  {value < 30
                    ? 'Mostly used for remote calls'
                    : value < 60
                    ? 'A mix of remote and in-person meetings'
                    : 'Mostly used for in-person collaboration'}
                  <span style={{ display: 'none' }}>{value}</span>
                </div>
              );
            }}
            min={0}
            max={100}
            showValue={false}
            style={{ marginBottom: '60px' }}
          />

          <ContinueButton
            className={`greenBlue`}
            type={`primary`}
            onClick={() => goToBox(4)}
          >
            <strong>Step 4:</strong> Occupancy
          </ContinueButton>
        </div>
      </ControlBox>
      <ControlBox
        isOpen={currentBox === 4}
        ref={(ref) => (controlBoxRefs.current[3] = ref)}
      >
        <h4
          className={`bluePink`}
          onClick={() =>
            currentBox === 4 ? goToBox(currentBox + 1) : goToBox(4)
          }
        >
          4. Occupancy
          <ExpandContractIcon isCurrent={currentBox === 4} />
        </h4>
        <div className={`controlBoxContent`}>
          <OccupancyControls
            values={values.culture.occupancy}
            onUpdated={setOccupancy}
            onDayChange={onDayChange}
          />

          <ContinueButton
            className={`bluePink`}
            type={`primary`}
            onClick={() => goToBox(5)}
          >
            <strong>Step 5:</strong> Calculate
          </ContinueButton>
        </div>
      </ControlBox>
      <ControlBox
        isOpen={currentBox === 5}
        ref={(ref) => (controlBoxRefs.current[4] = ref)}
      >
        <h4 className={`rainbow`} onClick={() => goToBox(5)}>
          5. Calculate your CollabScore
          <ExpandContractIcon isCurrent={currentBox === 5} />
        </h4>

        <div className={`controlBoxContent`}>
          <p style={{ marginTop: 0, padding: '0px 5px 0px' }}>
            This calculator gives you an estimation of your CollabScore based on
            the parameters you provided against authentic work models and
            scenarios.
          </p>
          <p style={{ marginTop: 0, padding: '5px 5px 5px' }}>
            For a more accurate assessment of your CollabScore,{' '}
            <a
              href={
                'https://robinpowered.com/solutions/in-person-collaboration'
              }
              target={'_blank'}
              rel={'noopener noreferrer'}
              style={{
                color: '#000000dd',
                textDecoration: 'underline',
              }}
              onClick={() => {
                track('opened-product-page', { origin: 'controls' });
              }}
            >
              try a demo of Robin
            </a>
            <Icon
              path={mdiOpenInNew}
              size={0.4}
              style={{
                verticalAlign: 'middle',
                color: '#000000dd',
              }}
            />
            .
          </p>
          <ContinueButton
            className={'rainbow'}
            type="primary"
            onClick={() => {
              return onSubmitted(values);
            }}
          >
            <Icon
              path={mdiCreation}
              size={'15px'}
              style={{ margin: '3px 8px' }}
            />
            Calculate
          </ContinueButton>
        </div>
      </ControlBox>
    </ControlsWrapper>
  );
};

export default Controls;
