import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styled from '@emotion/styled';
import { commonColors } from '../utils/colors';
import Desk from './Desk';
import { CallBooth } from './MapArea';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import { track } from '@amplitude/analytics-browser';

const KeyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${commonColors.darkPurpleSurface}FC;
  border-radius: 5px;
  color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

const SectionLabel = styled.label`
  display: block;
  font-size: 11px;
  font-weight: 800;
  color: white;
  margin-bottom: 10px;
`;

const RoomColorBlock = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 1px;
  background-color: ${(props) => props.color};
`;

const KeyLabel = styled.label`
  font-size: 10px;
  font-weight: 500;
  color: white;
  margin: 0;
`;

const MapKey: React.FC = forwardRef((props, ref) => {
  const [isMinimized, setIsMinimized] = useState(true);

  useImperativeHandle(ref, () => ({
    setIsMinimized,
  }));

  return (
    <KeyWrapper style={{ padding: isMinimized ? '2px 6px 4px 6px' : '10px' }}>
      {!isMinimized && (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            alignItems: 'start',
            columnGap: '20px',
          }}
        >
          <div>
            <SectionLabel>Rooms</SectionLabel>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <RoomColorBlock color={commonColors.roomGreen} />
              <KeyLabel>usually free</KeyLabel>
              <RoomColorBlock color={commonColors.roomGreenYellow} />
              <KeyLabel></KeyLabel>
              <RoomColorBlock color={commonColors.roomYellow} />
              <KeyLabel></KeyLabel>
              <RoomColorBlock color={commonColors.roomRed} />
              <KeyLabel>always in use</KeyLabel>
              <RoomColorBlock
                style={{ marginTop: '10px' }}
                color={commonColors.roomPrivateOffice}
              />
              <KeyLabel style={{ marginTop: '10px' }}>private office</KeyLabel>
            </div>
          </div>
          <div>
            <SectionLabel>Desks</SectionLabel>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                alignItems: 'center',
                gap: '5px',
                marginBottom: '20px',
              }}
            >
              <Desk isVertical={false} sizeMultiplier={1} isOccupied={false} />
              <KeyLabel>unoccupied</KeyLabel>
              <Desk isVertical={false} sizeMultiplier={1} isOccupied={true} />
              <KeyLabel>occupied</KeyLabel>
            </div>
            <SectionLabel>Call Booths</SectionLabel>
            <CallBooth />
          </div>
        </div>
      )}
      <div style={{ width: '100%', textAlign: 'right' }}>
        <button
          onClick={() => {
            setIsMinimized(!isMinimized);

            track('toggled-mapkey', { is_open: !isMinimized });
          }}
          style={{
            backgroundColor: 'transparent',
            color: 'white',
            border: 0,
            padding: 0,
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '11px',
          }}
        >
          {isMinimized ? 'show' : 'hide'} key
          <Icon
            path={isMinimized ? mdiChevronUp : mdiChevronDown}
            size={0.6}
            color="white"
            style={{ paddingTop: '5px' }}
          />
        </button>
      </div>
    </KeyWrapper>
  );
});

export default MapKey;
