import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Radio, Select, SelectProps } from 'antd';
import { trackInputChanged } from '../services/amplitude';
import { commonColors } from '../utils/colors';

const ControlsWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
`;

export interface PolicyControlValues {
  policyType: 'flexible' | 'partial' | 'required';
  policyDays?: number;
  policyFrequency?: 'week' | 'month';
}

interface PolicyControlProps {
  values: PolicyControlValues;
  onUpdated: (values: PolicyControlValues) => void;
}

const Choice = styled(Radio.Button)`
  &.ant-radio-button-wrapper,
  &.ant-radio-button-wrapper::before {
    transition: none !important;
  }
  &.ant-radio-button-wrapper-checked,
  &.ant-radio-button-wrapper-checked:hover {
    border-color: ${commonColors.controlsOrange} !important;
    &::before {
      background-color: ${commonColors.controlsOrange} !important;
      &:hover {
        background-color: ${commonColors.controlsOrange} !important;
      }
    }
  }
`;

const StyledSelect = styled(Select)`
  &.ant-select-focused .ant-select-selector,
  .ant-select-selector:hover {
    border-color: ${commonColors.controlsOrange} !important;
  }
`;

const Controls: React.FC<PolicyControlProps> = ({ values, onUpdated }) => {
  const [daysValue, setDaysValue] = useState<number>(values.policyDays ?? 3);
  const [frequencyValue, setFrequencyValue] = useState<'week' | 'month'>(
    values.policyFrequency ?? 'week'
  );

  const setPolicyType = useCallback(
    (value: 'flexible' | 'partial' | 'required') => {
      const newValues = {
        ...values,
        policyType: value,
      };
      if (value !== 'partial') {
        newValues.policyDays = undefined;
        newValues.policyFrequency = undefined;
      } else {
        newValues.policyDays = daysValue;
        newValues.policyFrequency = frequencyValue;
      }

      onUpdated(newValues);
      trackInputChanged('policy.type', 'radio', value);
    },
    [onUpdated, values, daysValue, frequencyValue]
  );

  const setDays = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any) => {
      if (typeof value !== 'number' && value !== null) {
        console.error('Invalid days value');
        return;
      }
      setDaysValue(value ?? 0);
      onUpdated({
        ...values,
        policyDays: value ?? 0,
      });
      if (value) {
        trackInputChanged('policy.days', 'dropdown', value);
      }
    },
    [onUpdated, values]
  );

  const setFrequency = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any) => {
      if (value !== 'week' && value !== 'month') {
        console.error('Invalid frequency value');
        return;
      }
      setFrequencyValue(value);
      onUpdated({
        ...values,
        policyFrequency: value,
      });
      trackInputChanged('policy.frequency', 'dropdown', value);
    },
    [onUpdated, values]
  );

  return (
    <ControlsWrapper>
      <Radio.Group
        defaultValue="partial"
        size={'small'}
        value={values.policyType}
        style={{
          marginTop: '5px',
          marginBottom: '10px',
          flex: 1,
          width: '100%',
          display: 'flex',
        }}
        onChange={(e) => {
          setPolicyType(e.target.value);
        }}
      >
        <Choice
          value="flexible"
          style={{
            flex: 0.3,
            textAlign: 'center',
            fontSize: '13px',
          }}
        >
          None
        </Choice>
        <Choice
          value="partial"
          style={{ flex: 0.5, textAlign: 'center', fontSize: '13px' }}
        >
          Partial
        </Choice>
        <Choice
          value="required"
          style={{ flex: 1, textAlign: 'center', fontSize: '13px' }}
        >
          5 Days Required
        </Choice>
      </Radio.Group>
      {values.policyType === 'partial' && (
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <StyledSelect
            value={values.policyDays}
            size={'small'}
            style={{ flex: 0, minWidth: '100px' }}
            onChange={setDays}
          >
            {Array.from(
              { length: values.policyFrequency === 'week' ? 5 : 31 },
              (_, i) => i + 1
            ).map((num) => (
              <Select.Option key={num} value={num}>
                <span>{num}</span>
                <span style={{ color: '#00000066', marginLeft: '5px' }}>
                  {num === 1 ? 'day' : 'days'}
                </span>
              </Select.Option>
            ))}
          </StyledSelect>
          <StyledSelect
            value={values.policyFrequency}
            size={'small'}
            style={{ flex: 1, marginLeft: '10px' }}
            options={[
              { value: 'week', label: 'per week' },
              { value: 'month', label: 'per month' },
            ]}
            onChange={setFrequency}
          />
        </div>
      )}
    </ControlsWrapper>
  );
};

export default Controls;
