import React from 'react';
import { commonColors } from '../utils/colors';
import { Day } from '../interfaces/types';
import styled from '@emotion/styled';
import { useControlContext } from '../App';
import { track } from '@amplitude/analytics-browser';

const DayPickerInner = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(5, 1fr);
`;

const WeekDayInner: React.FC<{
  day: string;
  currentDay: Day;
  className?: string;
}> = ({ day, currentDay, className }) => {
  const controlContext = useControlContext();
  return (
    <div
      onClick={() => {
        controlContext?.setCurrentDay(day as Day);
        track('clicked-day', { day });
      }}
      className={`${className} ${currentDay === day ? 'is-current' : ''}`}
    >
      {day.slice(0, 2).toUpperCase()}
    </div>
  );
};

const WeekDay = styled(WeekDayInner)`
  display: flex;
  align-content: center;
  margin: 0 2px;
  height: 32px;
  width: 32px;
  background-color: ${commonColors.darkPurpleSurface}cc;
  border-radius: 5px;
  border: none;
  color: #ffffffaa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;

  &.is-current {
    background-color: #a41666;
    color: white;
    border: 1px solid #ffffff88;
  }

  @media (max-width: 500px) {
    font-size: 10px;
    height: 28px;
    width: 28px;
  }
`;

const DayPicker: React.FC<{ currentDay: Day }> = ({ currentDay }) => {
  return (
    <DayPickerInner>
      <WeekDay day={'monday'} currentDay={currentDay} />
      <WeekDay day={'tuesday'} currentDay={currentDay} />
      <WeekDay day={'wednesday'} currentDay={currentDay} />
      <WeekDay day={'thursday'} currentDay={currentDay} />
      <WeekDay day={'friday'} currentDay={currentDay} />
    </DayPickerInner>
  );
};

export default DayPicker;
