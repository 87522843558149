import { useEffect, useState } from 'react';
import { commonColors, hexToRgb, interpolateColor } from '../utils/colors';
import { Day } from '../interfaces/types';

export const useDeskOccupancy = (
  desks: number,
  occupancyRate: number,
  currentDay: Day,
  deskCollaborationFactor: number,
  showDetails: boolean
) => {
  const [occupiedDesks, setOccupiedDesks] = useState<boolean[]>(
    Array(desks).fill(false)
  );

  const recomputeOccupancy = () => {
    const deskArray = computeOccupancy(
      desks,
      occupancyRate,
      deskCollaborationFactor
    );
    setOccupiedDesks(deskArray);
  };

  useEffect(() => {
    if (showDetails) {
      recomputeOccupancy();
    }
  }, [desks, occupancyRate, deskCollaborationFactor, showDetails, currentDay]); // eslint-disable-line react-hooks/exhaustive-deps

  return occupiedDesks;
};

export const computeOccupancy = (
  desks: number,
  occupancyRate: number,
  deskCollaborationFactor: number
) => {
  const totalOccupied = Math.round(desks * occupancyRate);
  const occupiedIndexes = new Set<number>();
  const deskCollaborationFactorSquared = deskCollaborationFactor ** 1.3;

  // desks per person should be `desks / totalOccupied` when deskCollaborationFactor is 0
  // as deskCollaborationFactor approaches 1, the desks per person should approach 1
  const desksPerPerson =
    desks / totalOccupied -
    (desks / totalOccupied - 1) * deskCollaborationFactorSquared;

  const clusterSize = Math.ceil(5 * deskCollaborationFactorSquared + 1);

  if (totalOccupied > 0) {
    let lastOccupied = 0;
    let currentClusterSize = 0;
    if (occupancyRate <= 0.7) {
      lastOccupied = Math.floor(Math.random() * 4);
    }
    occupiedIndexes.add(lastOccupied);
    for (let i = 0; i < totalOccupied - 1; i++) {
      const doCluster =
        Math.random() * clusterSize * 3 < deskCollaborationFactorSquared;
      if (doCluster) {
        currentClusterSize = clusterSize;
      }

      if (currentClusterSize === 0) {
        const jitter =
          occupancyRate <= 0.7 && deskCollaborationFactorSquared > 0.2
            ? Math.random() *
              (1 - deskCollaborationFactorSquared) *
              Math.pow(1 - occupancyRate, 2)
            : 0;
        lastOccupied += desksPerPerson + jitter;
      } else {
        currentClusterSize--;
        lastOccupied += 1;
      }

      occupiedIndexes.add(Math.floor(lastOccupied));
    }

    if (lastOccupied > desks) {
      console.warn('Overflowed desks');
    }
  }

  return Array(desks)
    .fill(false)
    .map((_, index) => occupiedIndexes.has(index));
};

export const useRoomColors = (
  showDetails: boolean,
  roomOccupancyRate: number,
  rooms: number,
  privateOffices: number
) => {
  const [roomColors, setRoomColors] = useState<object[]>([]);
  useEffect(() => {
    if (showDetails) {
      setRoomColors(
        Array.from({ length: rooms }, (val, index: number) => {
          if (index < privateOffices) {
            return {
              backgroundColor: '#e5e5e5',
              borderColor: '#eee',
              borderStyle: 'outset',
              borderWidth: 1,
            };
          } else {
            return getColorsForOccupancy(roomOccupancyRate);
          }
        })
      );
    }
  }, [showDetails, roomOccupancyRate, rooms, privateOffices]); // eslint-disable-line react-hooks/exhaustive-deps

  return roomColors;
};

export const getColorsForOccupancy = (occupancyScore: number) => {
  const doJitter =
    occupancyScore > 0.1 && occupancyScore < 0.9 && Math.random() > 0.66;
  const jitter = doJitter ? Math.random() * 0.4 - 0.2 : 0; // Generates a value between -0.1 and 0.1
  const jitteredScore =
    Math.round(Math.min(Math.max(occupancyScore + jitter, 0), 1) * 10) / 10;

  const startColor = hexToRgb(commonColors.roomGreen);
  const mid1Color = hexToRgb(commonColors.roomGreenYellow);
  const mid2Color = hexToRgb(commonColors.roomYellow);
  const endColor = hexToRgb(commonColors.roomRed);

  let color;
  if (jitteredScore <= 0.5) {
    color = interpolateColor(startColor, mid1Color, jitteredScore / 0.5);
  } else if (jitteredScore <= 0.7) {
    color = interpolateColor(mid1Color, mid2Color, (jitteredScore - 0.5) / 0.2);
  } else {
    color = interpolateColor(mid2Color, endColor, (jitteredScore - 0.7) / 0.3);
  }

  return {
    backgroundColor: `rgba(${Math.round(color[0])}, ${Math.round(
      color[1]
    )}, ${Math.round(color[2])}, .65)`,
    borderColor: `rgba(${Math.round(color[0])}, ${Math.round(
      color[1]
    )}, ${Math.round(color[2])}, .85)`,
    borderStyle: 'outset',
  };
};
