export const commonColors = {
  logo1: '#550331',
  logo2: '#A81F6B',

  controlsGreen: '#06c400',
  controlsGreenDarker: '#429c00',
  controlsGreenYellow: '#699e00',
  controlsYellow: '#ffc400',
  controlsYellowDarker: '#caaf00',
  controlsBlue: '#0081ff',
  controlsPink: '#f3009c',
  controlsOrange: '#ff0000',

  deskGrey: '#ccc',
  deskPurple: '#A81F6B',

  roomGreen: '#009b64',
  roomGreenYellow: '#80a400',
  roomYellow: '#ffb700',
  roomRed: '#c20034',
  roomPrivateOffice: '#e5e5e5',

  darkPurpleSurfaceLighter: '#6b004d',
  darkPurpleSurface: '#520045',
  darkPurpleSurfaceDarker: '#3f0035',
};

export const hexToRgb = (hex: string) => {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return [r, g, b];
};

export const interpolateColor = (
  color1: number[],
  color2: number[],
  fraction: number
) => {
  const r = color1[0] + (color2[0] - color1[0]) * fraction;
  const g = color1[1] + (color2[1] - color1[1]) * fraction;
  const b = color1[2] + (color2[2] - color1[2]) * fraction;
  return [r, g, b];
};
