import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as amplitude from '@amplitude/analytics-browser';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const amplitudeKey =
  process.env.REACT_APP_AMPLITUDE_KEY ?? window.env.REACT_APP_AMPLITUDE_KEY;

if (amplitudeKey) {
  amplitude.init(amplitudeKey, {
    defaultTracking: true,
  });
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/r/:shortcode" element={<App />} />
        <Route path="/*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
