import { track } from '@amplitude/analytics-browser';
import { getDebouncedFunction } from '../utils/debounce';
import { InputType } from '../interfaces/types';

export const trackInputChanged = (
  field: string,
  inputType: InputType,
  value: number | boolean | string,
  debounceTime = 3000
) => {
  getDebouncedFunction(
    field,
    () => {
      track('set-input', {
        field,
        input_type: inputType,
        value,
      });
    },
    debounceTime
  )();
};
