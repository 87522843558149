import React from 'react';
import styled from '@emotion/styled';
import { commonColors } from '../utils/colors';
import { Button } from 'antd';
import { mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import { useControlContext } from '../App';

const IntroContents = styled.div`
  background: ${commonColors.darkPurpleSurfaceDarker}cc;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  border-radius: 6px;
`;

const ActualContents = styled.div`
  background: transparent;
  color: white;
  padding: 20px;
  border-radius: 6px;
  background: ${commonColors.darkPurpleSurfaceDarker};
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  height: auto;
  min-height: 100%;
  p {
    color: #ffffffbb;
    font-size: 12px;
  }
  .images-scattered {
    display: flex;
  }
  .images-stacked {
    display: none;
  }

  @media (max-width: 500px), (min-width: 748px) and (max-width: 948px) {
    p {
      font-size: 10px;
    }
    padding: 20px;
    .images-scattered {
      display: none;
    }
    .images-stacked {
      display: flex;
    }
  }
`;

const Highlights = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: -5px;
  margin-right: -5px;
  @media (max-width: 500px), (min-width: 748px) and (max-width: 948px) {
    flex-direction: column;
    justify-content: left;
  }
`;

const HighlightBox = styled.div`
  flex: 1;
  padding: 10px;
  background: ${commonColors.darkPurpleSurfaceLighter};
  border: 1px solid #ffffff55;
  margin: 10px;
  border-radius: 5px;
  @media (max-width: 500px), (min-width: 748px) and (max-width: 948px) {
    margin: 4px;
    padding: 10px;
  }
  p {
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
    @media (max-width: 500px), (min-width: 748px) and (max-width: 948px) {
      justify-content: left;
      text-align: left;
      margin-top: 5px;
    }
  }
  label {
    width: 100%;
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: #ffffffff;
    justify-content: center;
    text-align: center;
    @media (max-width: 500px), (min-width: 748px) and (max-width: 948px) {
      font-size: 10px;
      justify-content: left;
      text-align: left;
      margin-bottom: 5px;
    }
  }
`;

const Intro: React.FC = () => {
  const controlContext = useControlContext();

  return (
    <IntroContents>
      <ActualContents>
        <h3>What is CollabScore?</h3>
        <p>
          CollabScore tells you how effective your office is at in-person
          collaboration relative to tens of thousands of workplaces around the
          world.
        </p>
        <p>
          There are 3 attributes of an office that drive in-person
          collaboration:
        </p>
        <Highlights>
          <HighlightBox>
            <label>Workplace Occupancy</label>
            <p>
              Rates your workplace occupancy against flexible workplaces around
              the world.
            </p>
          </HighlightBox>
          <HighlightBox>
            <label>Ad Hoc Collaboration</label>
            <p>
              How your physical setup and workplace policies create
              opportunities for ad hoc collaboration compared to others.
            </p>
          </HighlightBox>
          <HighlightBox>
            <label>Planned Collaboration</label>
            <p>
              Measures how many of your meetings are done solo or are teams
              meeting in-person and then compares you to other companies.
            </p>
          </HighlightBox>
        </Highlights>
        <div style={{ display: 'flex', marginTop: '20px' }}>
          <div style={{ flex: 1, marginRight: '5px' }}>
            <h3>How to use the calculator</h3>
            <p>
              Each step of the form will ask you about various aspects of your
              workplace. As you answer, you'll see a map representation of your
              office update in real time.
            </p>
            <p>
              The more accurate and detailed your answers, the more accurate
              your CollabScore will be.
            </p>
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '20px',
              }}
              onClick={() => {
                controlContext?.setShowIntro(false);
              }}
            >
              Get started <Icon path={mdiChevronRight} size={0.8} />
            </Button>
          </div>
          <div
            style={{
              flex: 1,
              flexDirection: 'column',
              height: '200px',
            }}
            className={'images-scattered'}
          >
            <img
              src={'/static/img/occupancy.png'}
              alt={'Occupancy slider screenshot'}
              style={{
                backgroundColor: '#fff',
                width: 150,
                transform: 'rotate(-14deg) translate(0px, 55px)',
                padding: '5px',
                borderRadius: '5px',
                boxShadow: '0 0 10px #00000033',
              }}
            />
            <img
              src={'/static/img/culture_rooms.png'}
              alt={'Room culture slider screenshot'}
              style={{
                backgroundColor: '#fff',
                width: 150,
                padding: '5px',
                borderRadius: '5px',
                marginLeft: '10px',
                transform: 'rotate(12deg) translate(130px, -95px)',
                boxShadow: '0 0 10px #00000033',
              }}
            />
            <img
              src={'/static/img/culture_seating.png'}
              alt={'Seating culture slider screenshot'}
              style={{
                backgroundColor: '#fff',
                width: 150,
                padding: '5px',
                borderRadius: '5px',
                marginLeft: '0px',
                transform: 'rotate(-3deg) translate(130px, -60px)',
                boxShadow: '0 0 10px #00000033',
              }}
            />
          </div>
          <div
            style={{
              flex: 0,
              flexDirection: 'column',
              height: '200px',
            }}
            className="images-stacked"
          >
            <img
              src={'/static/img/occupancy.png'}
              alt={'Occupancy slider screenshot'}
              style={{
                backgroundColor: '#fff',
                width: 120,
                padding: '5px',
                borderRadius: '5px',
                boxShadow: '0 0 10px #00000033',
                transform: 'translate(0px, 0px)',
              }}
            />
            <img
              src={'/static/img/culture_rooms.png'}
              alt={'Room culture slider screenshot'}
              style={{
                backgroundColor: '#fff',
                width: 120,
                padding: '5px',
                borderRadius: '5px',
                marginLeft: '10px',
                boxShadow: '0 0 10px #00000033',
                transform: 'translate(0px, -10px)',
              }}
            />
            <img
              src={'/static/img/culture_seating.png'}
              alt={'Room culture slider screenshot'}
              style={{
                backgroundColor: '#fff',
                width: 120,
                padding: '5px',
                borderRadius: '5px',
                marginLeft: '0px',
                boxShadow: '0 0 10px #00000033',
                transform: 'translate(0px, -20px)',
              }}
            />
          </div>
        </div>
      </ActualContents>
    </IntroContents>
  );
};

export default Intro;
