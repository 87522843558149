/* eslint-disable max-len */
import { CommonIconProps } from './common';
import React from 'react';

export const Facebook = ({
  size = {
    width: 24,
    height: 24,
  },
}: CommonIconProps) => (
  <svg
    width={typeof size === 'number' ? size : size.width}
    height={typeof size === 'number' ? size : size?.height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Facebook Logo</title>
    <path
      d="M16.5 6H13.5C12.9477 6 12.5 6.44772 12.5 7V10H16.5C16.6137 9.99748 16.7216 10.0504 16.7892 10.1419C16.8568 10.2334 16.8758 10.352 16.84 10.46L16.1 12.66C16.0318 12.8619 15.8431 12.9984 15.63 13H12.5V20.5C12.5 20.7761 12.2761 21 12 21H9.5C9.22386 21 9 20.7761 9 20.5V13H7.5C7.22386 13 7 12.7761 7 12.5V10.5C7 10.2239 7.22386 10 7.5 10H9V7C9 4.79086 10.7909 3 13 3H16.5C16.7761 3 17 3.22386 17 3.5V5.5C17 5.77614 16.7761 6 16.5 6Z"
      fill="currentColor"
    ></path>
  </svg>
);
export const LinkedIn = ({
  size = {
    width: 24,
    height: 24,
  },
}: CommonIconProps) => (
  <svg
    width={typeof size === 'number' ? size : size.width}
    height={typeof size === 'number' ? size : size?.height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>LinkedIn Logo</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3ZM8 18C8.27614 18 8.5 17.7761 8.5 17.5V10.5C8.5 10.2239 8.27614 10 8 10H6.5C6.22386 10 6 10.2239 6 10.5V17.5C6 17.7761 6.22386 18 6.5 18H8ZM7.25 9C6.42157 9 5.75 8.32843 5.75 7.5C5.75 6.67157 6.42157 6 7.25 6C8.07843 6 8.75 6.67157 8.75 7.5C8.75 8.32843 8.07843 9 7.25 9ZM17.5 18C17.7761 18 18 17.7761 18 17.5V12.9C18.0325 11.3108 16.8576 9.95452 15.28 9.76C14.177 9.65925 13.1083 10.1744 12.5 11.1V10.5C12.5 10.2239 12.2761 10 12 10H10.5C10.2239 10 10 10.2239 10 10.5V17.5C10 17.7761 10.2239 18 10.5 18H12C12.2761 18 12.5 17.7761 12.5 17.5V13.75C12.5 12.9216 13.1716 12.25 14 12.25C14.8284 12.25 15.5 12.9216 15.5 13.75V17.5C15.5 17.7761 15.7239 18 16 18H17.5Z"
      fill="currentColor"
    ></path>
  </svg>
);
export const XTwitter = ({
  size = {
    width: 24,
    height: 24,
  },
}: CommonIconProps) => (
  <svg
    width={typeof size === 'number' ? size : size.width}
    height={typeof size === 'number' ? size : size?.height}
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>X Logo</title>
    <path
      d="M152.256 108.416 247.488 0h-22.56l-82.731 94.112L76.171 0H0l99.872 142.325L0 256h22.56l87.307 -99.403L179.616 256h76.171M30.699 16.661h34.656l159.552 223.499h-34.667"
      fill="CurrentColor"
    ></path>
  </svg>
);
export const Instagram = ({
  size = {
    width: 24,
    height: 24,
  },
}: CommonIconProps) => (
  <svg
    width={typeof size === 'number' ? size : size.width}
    height={typeof size === 'number' ? size : size?.height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <title>Instagram Logo</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 3H8C5.23858 3 3 5.23858 3 8V16C3 18.7614 5.23858 21 8 21H16C18.7614 21 21 18.7614 21 16V8C21 5.23858 18.7614 3 16 3ZM19.25 16C19.2445 17.7926 17.7926 19.2445 16 19.25H8C6.20735 19.2445 4.75549 17.7926 4.75 16V8C4.75549 6.20735 6.20735 4.75549 8 4.75H16C17.7926 4.75549 19.2445 6.20735 19.25 8V16ZM16.75 8.25C17.3023 8.25 17.75 7.80228 17.75 7.25C17.75 6.69772 17.3023 6.25 16.75 6.25C16.1977 6.25 15.75 6.69772 15.75 7.25C15.75 7.80228 16.1977 8.25 16.75 8.25ZM12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5027 10.8057 16.0294 9.65957 15.1849 8.81508C14.3404 7.97059 13.1943 7.49734 12 7.5ZM9.25 12C9.25 13.5188 10.4812 14.75 12 14.75C13.5188 14.75 14.75 13.5188 14.75 12C14.75 10.4812 13.5188 9.25 12 9.25C10.4812 9.25 9.25 10.4812 9.25 12Z"
      fill="currentColor"
    ></path>
  </svg>
);
