import { DeskIconProps } from './common';

export const DeskProximityLow = ({
  size = 24,
  occupiedColor,
}: DeskIconProps) => (
  <svg
    width={typeof size === 'number' ? size : size.width}
    height={typeof size === 'number' ? size : size.height}
    fill="currentColor"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="14" y="13" fill="currentColor" rx="4" />
    <rect width="20" height="14" x="22" y="13" fill="currentColor" rx="4" />
    <rect width="20" height="14" x="44" y="13" fill="currentColor" rx="4" />
    <rect width="20" height="14" y="29" fill={occupiedColor} rx="4" />
    <rect width="20" height="14" x="22" y="29" fill="currentColor" rx="4" />
    <rect width="20" height="14" x="44" y="29" fill="currentColor" rx="4" />
  </svg>
);

export default DeskProximityLow;
