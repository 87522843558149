import React, { useEffect, useRef, useState } from 'react';
import { InputNumber, Slider, SliderSingleProps, Tooltip } from 'antd';
import { commonColors, hexToRgb, interpolateColor } from '../utils/colors';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';
import { InputType } from '../interfaces/types';
import styled from '@emotion/styled';

const StyledSlider = styled(Slider)`
  margin: 5px 0;
  .ant-slider-handle {
    padding: 10px;
    box-sizing: content-box;
    margin-top: -10px;
    margin-left: -10px;
    transform: translateX(-5px) !important;
    &::after, &::before {
     margin: 10px; 
    }
  },
`;
interface ColorStop {
  color: string;
  stop: number; // Percentage of the gradient stop
}

interface DraggableSliderProps {
  onSlideStarted?: () => void;
  onSlideEnded?: () => void;
  onChange?: (value: number, inputType: InputType) => void;
  value?: number;
  min?: number;
  max?: number;
  step?: number;
  showInput?: boolean;
  defaultValue?: number;
  label?: string;
  info?: ReactJSXElement;
  valueSuffix?: string;
  gradientStops?: ColorStop[];
  showValue?: boolean;
  target?: number;
  disable?: boolean;
  marks?: SliderSingleProps['marks'];
  style?: React.CSSProperties;
  colorFullBar?: boolean;
  tooltipFormatter?: (value: number | undefined) => string | ReactJSXElement;
  showTooltip?: boolean;
}

export const GreenBlueGradientStops: ColorStop[] = [
  { color: commonColors.controlsGreen, stop: 0 },
  { color: commonColors.controlsBlue, stop: 100 },
];

export const GreenYellowGradientStops: ColorStop[] = [
  { color: commonColors.controlsGreen, stop: 0 },
  { color: commonColors.controlsYellow, stop: 100 },
];

export const bluePinkGradientStops: ColorStop[] = [
  { color: commonColors.controlsBlue, stop: 0 },
  { color: commonColors.controlsPink, stop: 100 },
];

const DraggableSlider: React.FC<DraggableSliderProps> = ({
  onSlideStarted,
  onSlideEnded,
  onChange,
  value,
  min = 0,
  max = 100,
  step = 1,
  showInput = false,
  defaultValue = 0,
  label,
  info,
  valueSuffix = '',
  gradientStops = bluePinkGradientStops,
  showValue = true,
  target,
  disable = false,
  marks = {},
  style,
  colorFullBar = true,
  tooltipFormatter,
  showTooltip = true,
}) => {
  const [internalValue, setInternalValue] = useState<number>(defaultValue);
  const [isSliding, setIsSliding] = useState<boolean>(false);

  const sliderRef = useRef<{ focus: () => void; blur: () => void }>(null);

  // Sync internal value with external value prop
  useEffect(() => {
    if (value !== undefined) {
      setInternalValue(value);
    }
  }, [value]);

  const handleAfterChange = () => {
    setIsSliding(false);

    // I hate that I have to do this, but it seems like it's the only way to actually blur it.
    setTimeout(() => {
      if (sliderRef.current) {
        sliderRef.current.blur();
      }
    }, 300);

    if (onSlideEnded) {
      onSlideEnded();
    }
  };

  const handleChange = (
    value: number | number[] | null,
    inputType: InputType
  ) => {
    if (onSlideStarted && !isSliding) {
      setIsSliding(true);
      onSlideStarted();
    }

    let newValue = Array.isArray(value) ? value[0] : value;

    if (newValue === null) {
      newValue = 0;
    }
    if (newValue < min) {
      newValue = min;
    }
    if (newValue > max) {
      newValue = max;
    }

    setInternalValue(newValue);

    if (onChange) {
      onChange(newValue, inputType);
    }
  };

  const getGradientColor = (pct: number) => {
    pct = pct * 100; // Convert pct to a percentage in the range of 0-100

    if (gradientStops.length === 0) {
      return 'transparent';
    }

    if (gradientStops.length === 1 || pct === 0) {
      return gradientStops[0].color;
    }

    const sortedStops = gradientStops.sort((a, b) => a.stop - b.stop);
    let previousStop = sortedStops[0];
    let nextStop = sortedStops[1];

    for (let i = 1; i < sortedStops.length; i++) {
      if (pct <= sortedStops[i].stop) {
        nextStop = sortedStops[i];
        break;
      }
      previousStop = sortedStops[i];
    }

    const fraction =
      (pct - previousStop.stop) / (nextStop.stop - previousStop.stop);
    const color = interpolateColor(
      hexToRgb(previousStop.color),
      hexToRgb(nextStop.color),
      fraction
    );

    const stops = sortedStops
      .slice(0, sortedStops.indexOf(previousStop) + 1)
      .map((stop) => `${stop.color} ${stop.stop}%`);
    stops.push(`rgb(${color[0]}, ${color[1]}, ${color[2]}) ${pct}%`);

    return `linear-gradient(to right, ${stops.join(', ')})`;
  };

  const combinedMarks = { ...marks };
  if (target) {
    combinedMarks[target] = {
      label: 'target',
      style: { color: 'grey', fontSize: '9px' },
    };
  }

  useEffect(() => {
    if (max < internalValue) {
      setInternalValue(max);
    }
  }, [max]); // eslint-disable-line react-hooks/exhaustive-deps

  const colorStyleProps = {
    included: !colorFullBar,
    styles: {
      rail: {
        background: colorFullBar ? getGradientColor(1) : undefined,
      },
      track: {
        background: 'transparent',
      },
      tracks: {
        background: getGradientColor(internalValue / max),
      },
    },
  };

  return (
    <div style={{ marginBottom: `20px`, ...style }}>
      {label && (
        <label>
          {label}{' '}
          {!showInput && showValue && <span>{value + valueSuffix}</span>}
          {info && (
            <div
              style={{
                display: 'inline-block',
                transform: 'translateY(3px)',
              }}
            >
              <Tooltip title={info} overlayStyle={{ maxWidth: '300px' }}>
                <Icon
                  path={mdiInformationOutline}
                  size={0.65}
                  color={'#00000066'}
                />
              </Tooltip>
            </div>
          )}
        </label>
      )}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto auto',
        }}
      >
        <StyledSlider
          ref={sliderRef}
          {...colorStyleProps}
          disabled={disable}
          min={min}
          max={max}
          step={step}
          marks={combinedMarks}
          value={internalValue}
          onChange={(value) => {
            handleChange(value, 'slider');
          }}
          onChangeComplete={handleAfterChange}
          tooltip={{
            formatter:
              tooltipFormatter ??
              (valueSuffix ? (value) => `${value}${valueSuffix}` : undefined),
            open: showTooltip ? undefined : false,
          }}
        />
        {showInput && (
          <InputNumber
            disabled={disable}
            controls={false}
            min={min}
            max={max}
            step={step}
            value={internalValue}
            onChange={(value) => {
              handleChange(value, 'text');
              handleAfterChange();
            }}
            size="small"
            style={{ width: '50px', marginLeft: `15px` }}
          />
        )}
      </div>
    </div>
  );
};

export default DraggableSlider;
