import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import './App.css';
import { Col, Layout, Row } from 'antd';
import MapWindow from './components/MapWindow';
import Controls, { ControlValues } from './components/Controls';
import { Day, Result } from './interfaces/types';
import styled from '@emotion/styled';
import { Logo } from './components/icons/Logo';
import { commonColors } from './utils/colors';
import FooterLayout from './components/layout/FooterLayout';
import { calculate, getDefaultSummaries } from './utils/calculate';
import {
  loadIntroStatusFromLocalStorage,
  loadOldResultFromLocalStorage,
  saveIntroStatusToLocalStorage,
} from './utils/localStorage';
import { submitResultAndSave } from './services/apiService';
import { track } from '@amplitude/analytics-browser';
import { useNavigate, useParams } from 'react-router-dom';

const initialControlValues: ControlValues = {
  office: {
    floors: 1,
    desks: 144,
    rooms: 18,
    privateOffices: 0,
    callBooths: 5,
  },
  culture: {
    policy: {
      policyType: 'flexible',
      policyDays: 2,
      policyFrequency: 'week',
    },
    occupancy: {
      monday: 50,
      tuesday: 50,
      wednesday: 50,
      thursday: 50,
      friday: 50,
    },
    avgMeetingsPerDay: 3,
    workingProximity: 50,
    departmentsMix: false,
    roomCollaborationFactor: 50,
  },
};

const CalculatorLayout = styled(Layout)`
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
`;

const PageTitle = styled.h1`
  font-size: 20px;
  line-height: 1.2;
  padding-top: 8px;
  flex: 0;
  font-family: Brownllweb, Trebuchet MS, sans-serif;
  -webkit-text-fill-color: transparent;
  color: transparent;
  background: -webkit-linear-gradient(
    60deg,
    ${commonColors.logo1},
    ${commonColors.logo2}
  );
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 10px;
  @media (max-width: 440px) {
    font-size: 16px;
  }
  @media (max-width: 380px) {
    font-size: 14px;
  }
  @media (min-width: 2000px) {
    font-size: 28px;
  }
`;

const PageSubtitle = styled.h2`
  font-family: Brownllweb, Trebuchet MS, sans-serif;
  font-weight: 300;
  font-size: 20px;
  padding-top: 8px;
  margin-top: 10px;
  margin-left: 6px;
  line-height: 1.2;
  color: #33333399;
  @media (max-width: 440px) {
    font-size: 16px;
  }
  @media (max-width: 380px) {
    font-size: 14px;
  }
  @media (min-width: 2000px) {
    font-size: 28px;
  }
`;

const PoweredBy = styled.div`
  font-size: 12px;
  color: #333;
  margin-top: 5px;
  margin-left: 10px;
  flex: 0;
  text-align: right;
  line-height: 0;
  display: flex;
  align-items: center;

  label {
    font-size: 11px;
    font-weight: 600;
    color: rgba(31, 7, 22, 0.47);
    margin: 0 5px 0 0;
    width: 80px;
  }
`;

const Header = styled(Layout.Header)`
  padding: 0 30px;
  background-color: transparent;

  > div {
    display: flex;
    margin: auto;
    max-width: 1000px;

    @media (min-width: 2048px) {
      max-width: 1400px;
    }
  }

  @media (min-width: 2048px) {
    height: 80px;
  }

  @media (max-width: 440px) {
    height: 54px;
  }

  @media (max-width: 380px) {
    height: 48px;
  }
`;

const Content = styled(Layout.Content)`
  padding: 24px;
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 2048px) {
    max-width: 1450px;
  }
`;

const Footer = styled(Layout.Footer)`
  background-color: #fbfaf9;
  border-top: 1px solid #ddd;
  min-height: 300px;

  @media (min-height: 1400px) {
    min-height: 450px;
  }
`;

interface ControlContextProps {
  setShowResult: (showResult: boolean) => void;
  setShowIntro: (showIntro: boolean) => void;
  setCurrentDay: (currentDay: Day) => void;
  setIsCalculating: (isCalculating: boolean) => void;
}

const ControlContext = createContext<ControlContextProps | null>(null);

export const useControlContext = () => {
  return useContext(ControlContext);
};

function App() {
  const [isCalculating, setIsCalculating] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState(true);
  const [currentDay, setCurrentDay] = useState<Day>('monday');
  const [is3D, setIs3D] = useState<boolean>(false);
  const [result, setResult] = useState<Result | null>(null);
  const [showIntro, setShowIntro] = useState<boolean>(
    !loadIntroStatusFromLocalStorage()
  );
  const [showResult, setShowResult] = useState<boolean>(false);
  const [controlValues, setControlValues] =
    useState<ControlValues>(initialControlValues);
  const navigate = useNavigate();

  const handleDayChange = (day: Day) => {
    setCurrentDay(day);
  };

  useEffect(() => {
    saveIntroStatusToLocalStorage(true);
  }, []);

  const { shortcode } = useParams();

  useEffect(() => {
    if (shortcode && !result) {
      fetch(`/api/result/${shortcode}`)
        .then((response) => response.json())
        .then((data) => {
          setControlValues(data.inputs);
          setResult({
            result: data.result,
            summaries: data.summaries,
            shortCode: shortcode,
          });
          setShowResult(true);
          setIs3D(true);
          track('loaded-result');
        })
        .catch((error) => {
          console.error('Error fetching from the backend:', error);
        });
    }
  }, [shortcode, result]);

  const updateFavicon = (result: Result | null) => {
    const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
    if (!result) {
      link.href = '/static/img/collab-score-0.svg';
    } else {
      if (result.result.collabScore > 0.85) {
        link.href = '/static/img/collab-score-excellent.svg';
      } else if (result.result.collabScore > 0.7) {
        link.href = '/static/img/collab-score-v-good.svg';
      } else if (result.result.collabScore > 0.4) {
        link.href = '/static/img/collab-score-good.svg';
      } else {
        link.href = '/static/img/collab-score-below-average.svg';
      }
    }
  };

  useEffect(() => {
    updateFavicon(result);
  }, [result]);

  const onUpdated = useCallback(
    (values: ControlValues) => {
      setIs3D(true);
      setControlValues(values);
      setShowIntro(false);
      setShowResult(false);
    },
    [setControlValues]
  );

  const onSubmitted = useCallback((values: ControlValues) => {
    track('clicked-calculate');

    // async call calculate with  a promise, sleep for 10 seconds, then resolve it.
    setIsCalculating(true);
    setShowResult(true);
    setIs3D(true);
    setShowIntro(false);

    Promise.resolve(calculate(values)).then((result) => {
      submitResultAndSave(values, result)
        .then((apiResponse) => {
          setIsCalculating(false);

          // Update the URL with the shortcode
          navigate(`/r/${apiResponse.shortcode}`);

          setResult({
            result,
            summaries: apiResponse.summaries,
            shortCode: apiResponse.shortcode,
          });
        })
        .catch((error) => {
          console.error('Error saving to the backend:', error);
          setIsCalculating(false);
          setResult({ result, summaries: getDefaultSummaries(result) });
        });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadOldResult = (index: number) => {
    // Use the utility function to load an old result from localStorage
    const { oldValues, oldResult } = loadOldResultFromLocalStorage(index);

    // Update the state with the old values and result
    setControlValues(oldValues);
    setResult(oldResult);
  };

  useEffect(() => {
    if (isCalculating) {
      const interval = setInterval(() => {
        const days: Day[] = [
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
        ];
        const nextDay: Day = days[(days.indexOf(currentDay) + 1) % 5];
        setCurrentDay(nextDay);
      }, 60);
      return () => clearInterval(interval);
    }
  }, [currentDay, isCalculating]);

  return (
    <ControlContext.Provider
      value={{
        setShowResult,
        setShowIntro,
        setCurrentDay,
        setIsCalculating,
      }}
    >
      <CalculatorLayout>
        <Header>
          <div>
            <PageTitle>CollabScore</PageTitle>
            <PageSubtitle>Calculator</PageSubtitle>
            <div style={{ flex: 1 }} />
            <PoweredBy>
              <label>Powered by </label>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  (window.location.href = 'https://robinpowered.com')
                }
              >
                <Logo
                  size={{ height: 18, width: 55 }}
                  textColor={commonColors.logo1}
                  markColor={commonColors.logo2}
                />
              </div>
            </PoweredBy>
          </div>
        </Header>
        <Content>
          <Row gutter={[16, 16]}>
            <Col flex="auto" className="left-column">
              <MapWindow
                controlValues={controlValues}
                currentDay={currentDay}
                showDetails={showDetails}
                is3D={is3D}
                showIntro={showIntro}
                showResult={showResult}
                isCalculating={isCalculating}
                result={result}
              />
            </Col>
            <Col flex="none" className="right-column">
              <Controls
                values={controlValues}
                onUpdated={onUpdated}
                onDayChange={handleDayChange}
                onSlideStarted={(isLive = false) => {
                  if (!isLive) {
                    setShowDetails(false);
                  }
                }}
                onSlideEnded={() => setShowDetails(true)}
                onSubmitted={onSubmitted}
              />
            </Col>
          </Row>
        </Content>
        <Footer>
          <FooterLayout />
        </Footer>
      </CalculatorLayout>
    </ControlContext.Provider>
  );
}

export default App;
