import React, { useEffect } from 'react';
import Desk from './Desk';
import { MapAreaProps } from '../interfaces/mapAreas';
import { useDeskOccupancy, useRoomColors } from '../hooks/mapAreaHooks';
import styled from '@emotion/styled';
import MapArea, {
  CallBooth,
  LeftVerticalWall,
  Pod,
  PodVertical,
  Room,
} from './MapArea';
import { useElementSize } from '../hooks/resizeObserver';
import { useSizes } from '../contexts/SizeContext';

const AreaGrid = styled(MapArea)`
  display: grid;
  grid-template-columns: 1fr auto; /* Left side fills available space, right side wraps content */
  grid-template-rows: 1fr auto; /* Top row wraps content, bottom row fills space */
  border-top: 0;
`;

const DeskArea = styled.div`
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  z-index: 1;
`;

const BottomWall = styled.div`
  grid-column: 1;
  grid-row: 2;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const RightWall = styled.div`
  grid-column: 2;
  grid-row: 1 / span 2;
  display: flex;
  flex-direction: column;
`;

const MapArea3: React.FC<MapAreaProps> = ({
  multiplier,
  desks,
  rooms,
  privateOffices,
  callBooths,
  occupancy,
  currentDay,
  roomOccupancyRate,
  deskCollaborationFactor,
  showDetails = true,
}) => {
  const { ref: areaGrid, size } = useElementSize<HTMLDivElement>();
  const { setSizes } = useSizes();

  useEffect(() => {
    setSizes((prevSizes) => ({ ...prevSizes, mapArea3: size }));
  }, [size, setSizes]);

  const roomsRightWall = Math.min(rooms, 6);
  const roomsBottomWall = Math.min(rooms - roomsRightWall, 10);

  const desksLeftMax = 72;
  const desksRightMax = 72;

  const snappingFactor =
    desks > desksLeftMax && desks < desksLeftMax + 36 ? 36 : 0;
  const desksLeft = Math.min(desks, desksLeftMax - snappingFactor);
  const podsLeft = Math.ceil(desksLeft / 6);
  const desksRight = Math.min(
    desks - desksLeftMax + snappingFactor,
    desksRightMax
  );
  const podsRight = Math.ceil(desksRight / 6);

  const sideRoomStyle: React.CSSProperties = {
    width: `${60 * multiplier}px`,
    height: `${50 * multiplier}px`,
  };
  const topBottomRoomStyle: React.CSSProperties = {
    width: `${30 * multiplier}px`,
    height: `${40 * multiplier}px`,
  };

  const podGap = 2 * multiplier;

  const deskAreaStyle: React.CSSProperties = {
    position: `relative`,
    display: `grid`,
    margin: `auto`,
    paddingBottom: 30 * multiplier + 'px',
    paddingRight: 30 * multiplier + 'px',
    paddingLeft: 20 * multiplier + 'px',
    gap: 15 * multiplier + 'px',
    zIndex: 1,
  };
  const deskAreaLeftStyle: React.CSSProperties = {
    ...deskAreaStyle,
    paddingTop: 20 * multiplier + 'px',
    gridTemplateColumns: `repeat(${podsLeft <= 6 ? 1 : 2}, 1fr)`,
  };
  const deskAreaRightStyle: React.CSSProperties = {
    ...deskAreaStyle,
    paddingTop: 10 * multiplier + 'px',
    gridTemplateColumns: `repeat(${podsRight < 6 ? podsRight : 6}, 1fr)`,
  };
  const callBoothStyle: React.CSSProperties = {
    width: `${24 * multiplier}px`,
    height: `${24 * multiplier}px`,
    margin: `${2 * multiplier}px`,
    borderRadius: `${3 * multiplier}px`,
  };

  const occupiedDesks = useDeskOccupancy(
    desks,
    occupancy[currentDay] / 100,
    currentDay,
    deskCollaborationFactor,
    showDetails
  );
  const roomColors = useRoomColors(
    showDetails,
    roomOccupancyRate,
    rooms,
    privateOffices
  );

  return (
    <AreaGrid ref={areaGrid} style={{ marginTop: '-2px' }}>
      <LeftVerticalWall
        style={{
          top: `1px`,
          width: 30 * multiplier + 'px',
          transform: `rotateY(90deg) translateZ(-${
            15 * multiplier
          }px) translateX(-${15 * multiplier}px)`,
        }}
      ></LeftVerticalWall>
      <DeskArea>
        <div style={deskAreaLeftStyle}>
          {Array.from({ length: podsLeft }, (_, podIndex) => (
            <Pod key={podIndex} style={{ gap: podGap + 'px' }}>
              {Array.from(
                {
                  length:
                    podIndex < Math.floor(desksLeft / 6) ? 6 : desksLeft % 6,
                },
                (_, deskIndex) => {
                  const absoluteDeskIndex = podIndex * 6 + deskIndex; // Calculate the absolute index of the desk
                  return (
                    <Desk
                      key={deskIndex}
                      isVertical={false}
                      sizeMultiplier={multiplier}
                      isOccupied={occupiedDesks[absoluteDeskIndex]}
                    />
                  );
                }
              )}
            </Pod>
          ))}
        </div>
        <div style={deskAreaRightStyle}>
          {Array.from({ length: podsRight }, (_, podIndex) => (
            <PodVertical key={podIndex} style={{ gap: podGap + 'px' }}>
              {Array.from(
                {
                  length:
                    podIndex < Math.floor(desksRight / 6) ? 6 : desksRight % 6,
                },
                (_, deskIndex) => {
                  const absoluteDeskIndex =
                    desksLeft + podIndex * 6 + deskIndex; // Calculate the absolute index of the desk
                  return (
                    <Desk
                      key={deskIndex}
                      isVertical={true}
                      sizeMultiplier={multiplier}
                      isOccupied={occupiedDesks[absoluteDeskIndex]}
                    />
                  );
                }
              )}
            </PodVertical>
          ))}
        </div>
      </DeskArea>
      <BottomWall>
        {Array.from({ length: callBooths }, (_, roomIndex) => (
          <CallBooth key={`top-${roomIndex}`} style={callBoothStyle} />
        ))}
        {Array.from({ length: roomsBottomWall }, (_, roomIndex) => (
          <Room
            key={`bottom-${roomIndex}`}
            style={{
              ...topBottomRoomStyle,
              ...roomColors[roomIndex + roomsRightWall],
            }}
          ></Room>
        ))}
      </BottomWall>
      <RightWall>
        {Array.from({ length: roomsRightWall }, (_, roomIndex) => (
          <Room
            key={`bottom-${roomIndex}`}
            style={{
              ...sideRoomStyle,
              ...roomColors[roomIndex],
            }}
          ></Room>
        ))}
      </RightWall>
    </AreaGrid>
  );
};

export default MapArea3;
