import React, { useEffect, useRef, useState } from 'react';
import MapArea1 from './MapArea1';
import MapArea2 from './MapArea2';
import MapArea3 from './MapArea3';
import styled from '@emotion/styled';
import { CommonMapProps } from '../interfaces/mapAreas';
import { SizeProvider } from '../contexts/SizeContext';
import MapShadow from './MapShadow';
import MapKey from './MapKey';
import { getRoomOccupancyRate } from '../utils/occupancy';
import { useControlContext } from '../App';
import DayPicker from './DayPicker';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';
import { track } from '@amplitude/analytics-browser';

export interface MapProps extends CommonMapProps {
  floors: number;
  workingProximity: number;
  roomCollaborationFactor: number;
  avgMeetingsPerDay: number;
  is3D: boolean;
}

const areas = [
  {
    area: 0,
    desks: 4 * 3 * 6,
    rooms: 13,
    callBooths: 13,
  },
  {
    area: 1,
    desks: 6 * 2 * 12,
    rooms: 16,
    callBooths: 6,
  },
  {
    area: 0,
    desks: 4 * 6 + 6 * 5,
    rooms: 0,
    callBooths: 0,
  },
  {
    area: 2,
    desks: 6 * 2 * 12,
    rooms: 16,
    callBooths: 6,
  },
  {
    area: 0,
    desks: 4 * 6,
    rooms: 0,
    callBooths: 0,
  },
];

function allocateResources(
  desks: number,
  rooms: number,
  privateOffices: number,
  callBooths: number,
  bufferAmount = 18
) {
  let remainingDesks = desks;
  let remainingRooms = rooms;
  let remainingPrivateOffices = privateOffices;
  let remainingCallBooths = callBooths;
  const deskAllocations = [];
  const roomAllocations = [];
  const privateOfficesAllocations = [];
  const callBoothAllocations = [];

  for (const area of areas) {
    deskAllocations[area.area] = 0;
    roomAllocations[area.area] = 0;
    privateOfficesAllocations[area.area] = 0;
    callBoothAllocations[area.area] = 0;
  }

  for (const area of areas) {
    const areaDesks = Math.min(remainingDesks, area.desks);
    const areaRooms = Math.min(remainingRooms, area.rooms);
    const areaCallBooths = Math.min(remainingCallBooths, area.callBooths);
    deskAllocations[area.area] += areaDesks;
    roomAllocations[area.area] += areaRooms;
    callBoothAllocations[area.area] += areaCallBooths;
    remainingDesks -= areaDesks;
    remainingRooms -= areaRooms;
    remainingCallBooths -= areaCallBooths;
  }

  // iterate over roomAllocations in reverse
  // distribute private offices into these areas
  // keep an even split but make sure that the number of private offices is never more than the number of rooms
  for (let i = roomAllocations.length - 1; i >= 0; i--) {
    const areaRooms = roomAllocations[i];
    const areaPrivateOffices = Math.min(
      Math.floor(remainingPrivateOffices / (i + 1)),
      areaRooms
    );
    privateOfficesAllocations[i] = areaPrivateOffices;
    remainingPrivateOffices -= areaPrivateOffices;
  }

  // If the last area has fewer than buffer amount, move `bufferAmount - desksAlreadyInLastArea` desks
  // from the second-to-last area to the last area
  if (
    deskAllocations[deskAllocations.length - 1] < bufferAmount &&
    deskAllocations[deskAllocations.length - 1] > 0
  ) {
    const desksToMove =
      bufferAmount - deskAllocations[deskAllocations.length - 1];
    deskAllocations[deskAllocations.length - 1] += desksToMove;
    deskAllocations[deskAllocations.length - 2] -= desksToMove;
  }

  return {
    deskAllocations,
    roomAllocations,
    privateOfficesAllocations,
    callBoothAllocations,
  };
}

interface Allocations {
  deskAllocations: number[];
  roomAllocations: number[];
  privateOfficesAllocations: number[];
  callBoothAllocations: number[];
}

const MapContainer = styled.div`
  perspective: 2000px;
  position: relative;
  width: 100%;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Floor = styled.div`
  margin-top: -40px;
  display: block;
  position: absolute;
  transform-origin: center;
  transform-style: preserve-3d; /* Preserve 3D transformations */
  transition: transform 0.5s;
`;

const Map: React.FC<MapProps> = ({
  floors,
  desks,
  rooms,
  privateOffices,
  callBooths,
  occupancy,
  workingProximity,
  roomCollaborationFactor,
  avgMeetingsPerDay,
  showDetails = true,
  is3D = false,
  currentDay,
}) => {
  const [allocations, setAllocations] = useState<Allocations>({
    deskAllocations: [],
    roomAllocations: [],
    privateOfficesAllocations: [],
    callBoothAllocations: [],
  });
  const [multiplier, setMultiplier] = useState(1);

  const controlContext = useControlContext();

  useEffect(() => {
    const {
      deskAllocations,
      roomAllocations,
      privateOfficesAllocations,
      callBoothAllocations,
    } = allocateResources(desks, rooms, privateOffices, callBooths);
    setAllocations({
      deskAllocations,
      roomAllocations,
      privateOfficesAllocations,
      callBoothAllocations,
    });
  }, [desks, rooms, privateOffices, callBooths]);

  const mapContainerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    // Copy ref.current to a variable inside the effect
    const currentRef = mapContainerRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // Update state with both width and height
        setContainerSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    // Cleanup using the copied ref
    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
    // Since we're not using `mapContainerRef` directly in the effect's dependencies,
    // it's okay to omit it from the dependency array
  }, []);

  const [height, setHeight] = useState(550);

  useEffect(() => {
    const modifier =
      desks > 300 || rooms > 29 ? 0.65 : desks > 200 || rooms > 17 ? 0.8 : 1;
    let newMultiplier;

    if (containerSize.width < 400) {
      newMultiplier = 0.5 * modifier;
      setHeight(300);
    } else if (containerSize.width < 450) {
      newMultiplier = 0.65 * modifier;
      setHeight(400);
    } else if (containerSize.width < 600) {
      newMultiplier = 0.8 * modifier;
      setHeight(450);
    } else if (containerSize.width < 800) {
      newMultiplier = 1 * modifier;
      setHeight(550);
    } else {
      newMultiplier = 1.5 * modifier;
      setHeight(750);
    }

    if (multiplier !== newMultiplier) {
      setMultiplier(newMultiplier);
    }
  }, [desks, rooms, containerSize.width, containerSize.height]); // eslint-disable-line react-hooks/exhaustive-deps

  const [roomOccupancyRate, setRoomOccupancyRate] = useState(0);

  useEffect(() => {
    if (showDetails) {
      const newRoomOccupancyRate = getRoomOccupancyRate(
        desks,
        rooms,
        privateOffices,
        occupancy[currentDay],
        avgMeetingsPerDay,
        roomCollaborationFactor
      );
      setRoomOccupancyRate(newRoomOccupancyRate);
    }
    // If showDetails is false, do not update roomOccupancyRate.
    // It retains the last updated value while showDetails was true.
  }, [
    desks,
    rooms,
    privateOffices,
    occupancy,
    avgMeetingsPerDay,
    roomCollaborationFactor,
    showDetails,
    currentDay,
  ]);

  const area1Visible =
    allocations.deskAllocations[0] > 0 ||
    allocations.roomAllocations[0] > 0 ||
    allocations.callBoothAllocations[0] > 0;
  const area2Visible =
    allocations.deskAllocations[1] > 0 ||
    allocations.roomAllocations[1] > 0 ||
    allocations.callBoothAllocations[1] > 0;
  const area3Visible =
    allocations.deskAllocations[2] > 0 ||
    allocations.roomAllocations[2] > 0 ||
    allocations.callBoothAllocations[2] > 0;

  return (
    <SizeProvider>
      <MapContainer ref={mapContainerRef} style={{ height: height + 'px' }}>
        <Floor
          id={'floor'}
          style={{
            transform: `rotateX(${is3D ? 45 : 0}deg) rotateZ(${
              is3D ? 45 : 0
            }deg)`,
            zIndex: `0`,
          }}
        >
          {area1Visible && (
            <MapArea1
              multiplier={multiplier}
              desks={allocations.deskAllocations[0]}
              rooms={allocations.roomAllocations[0]}
              privateOffices={allocations.privateOfficesAllocations[0]}
              callBooths={allocations.callBoothAllocations[0]}
              occupancy={occupancy}
              currentDay={currentDay}
              roomOccupancyRate={roomOccupancyRate}
              deskCollaborationFactor={workingProximity / 100}
              showDetails={showDetails}
            />
          )}

          {area2Visible && (
            <MapArea2
              multiplier={multiplier}
              desks={allocations.deskAllocations[1]}
              rooms={allocations.roomAllocations[1]}
              privateOffices={allocations.privateOfficesAllocations[1]}
              callBooths={allocations.callBoothAllocations[1]}
              occupancy={occupancy}
              currentDay={currentDay}
              roomOccupancyRate={roomOccupancyRate}
              deskCollaborationFactor={workingProximity / 100}
              showDetails={showDetails}
            />
          )}

          {area3Visible && (
            <MapArea3
              multiplier={multiplier}
              desks={allocations.deskAllocations[2]}
              rooms={allocations.roomAllocations[2]}
              privateOffices={allocations.privateOfficesAllocations[2]}
              callBooths={allocations.callBoothAllocations[2]}
              occupancy={occupancy}
              currentDay={currentDay}
              roomOccupancyRate={roomOccupancyRate}
              deskCollaborationFactor={workingProximity / 100}
              showDetails={showDetails}
            />
          )}
        </Floor>

        {[...Array(Math.min(floors - 1, 14))].map((_, i) => (
          <Floor
            key={i}
            style={{
              opacity: `${0.1 + (1 - 0.1) * Math.exp(-0.3 * i)}`,
              transform: `rotateX(${is3D ? 45 : 0}deg) translateZ(-${
                (i + 1) * 30 * multiplier
              }px) rotateZ(${is3D ? 45 : 0}deg)`,
              zIndex: `-${i + 1}`,
            }}
          >
            <MapShadow
              area1Visible={area1Visible}
              area2Visible={area2Visible}
              area3Visible={area3Visible}
            />
          </Floor>
        ))}
        <div
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            margin: '10px',
            zIndex: 75,
            cursor: 'pointer',
          }}
          onClick={() => {
            controlContext?.setShowIntro(true);
            track('opened-intro');
          }}
        >
          <Icon path={mdiInformation} size={1} color={'#ffffff'} />
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: '0',
            right: '0',
            margin: '10px',
            zIndex: 75,
          }}
        >
          <MapKey />
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            margin: '10px',
            zIndex: 74,
          }}
        >
          <DayPicker currentDay={currentDay} />
        </div>
      </MapContainer>
    </SizeProvider>
  );
};

export default Map;
