import React from 'react';
import Intro from './Intro';
import Map from './Map';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import ResultView from './Result';
import { ControlValues } from './Controls';
import { Day, Result } from '../interfaces/types';
import { useControlContext } from '../App';

const rotateGradient = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const MapWindowWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 0;
  ::before,
  ::after {
    content: '';
    position: absolute;
    top: -50%; /* Increase size and position to ensure full coverage during rotation */
    left: -50%;
    width: 200%; /* Larger than the container to maintain coverage during rotation */
    height: 200%;
    border-radius: 10px;
    background-size: 200% 200%;
    opacity: 0.9;
  }
  ::before {
    background-image: linear-gradient(
      45deg,
      rgba(0, 21, 255, 0.6),
      rgba(112, 0, 73, 0.4),
      rgba(60, 0, 241, 0.9)
    );
    animation: ${rotateGradient} 13s linear infinite reverse;
    z-index: -1;
  }
  ::after {
    background-color: rgb(152, 0, 100);
    z-index: -2;
  }
  &.showingResult {
    min-height: 650px;
  }

  @media (max-width: 500px), (min-width: 748px) and (max-width: 948px) {
    &.showingIntro {
      min-height: 600px;
    }
  }
`;

interface MapWindowProps {
  controlValues: ControlValues;
  currentDay: Day;
  showDetails: boolean;
  showIntro: boolean;
  showResult: boolean;
  isCalculating: boolean;
  result: Result | null;
  is3D: boolean;
}

const MapWindow: React.FC<MapWindowProps> = ({
  controlValues,
  currentDay,
  showDetails = true,
  is3D = false,
  showIntro = false,
  showResult = false,
  isCalculating = false,
  result,
}) => {
  const controlContext = useControlContext();
  return (
    <div style={{ backgroundColor: '#eee', borderRadius: '10px' }}>
      <MapWindowWrapper
        className={`${showResult && !isCalculating ? 'showingResult' : ''} ${
          showIntro ? 'showingIntro' : ''
        }`}
      >
        {showIntro && <Intro />}
        {showResult && (
          <ResultView
            controlValues={controlValues}
            isCalculating={isCalculating}
            result={result ?? null}
          />
        )}
        <div style={{ filter: showIntro ? 'blur(2px)' : '' }}>
          <Map
            floors={controlValues.office.floors}
            desks={controlValues.office.desks}
            rooms={controlValues.office.rooms}
            privateOffices={controlValues.office.privateOffices}
            callBooths={controlValues.office.callBooths}
            occupancy={controlValues.culture.occupancy}
            workingProximity={controlValues.culture.workingProximity}
            roomCollaborationFactor={
              controlValues.culture.roomCollaborationFactor
            }
            currentDay={currentDay}
            avgMeetingsPerDay={controlValues.culture.avgMeetingsPerDay}
            showDetails={showDetails}
            is3D={is3D}
          />
        </div>
      </MapWindowWrapper>
    </div>
  );
};

export default MapWindow;
