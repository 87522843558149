import { debounce, DebouncedFunc } from 'lodash';

// Create a map to store the debounced functions
const debouncedFuncMap: Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DebouncedFunc<(...args: any[]) => any>
> = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getDebouncedFunction<T extends (...args: any[]) => any>(
  id: string,
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  // Cancel the previous debounced function if it exists
  if (debouncedFuncMap[id]) {
    debouncedFuncMap[id].cancel();
  }

  // Always create a new debounced function
  debouncedFuncMap[id] = debounce(func, wait);

  return (...args: Parameters<T>) => {
    debouncedFuncMap[id](...args);
  };
}
