export function getBaseUrl(useVanityUrl = false, shortUrl = false) {
  if (useVanityUrl) {
    const env = window.env.REACT_APP_ROBIN_ENV;

    if (env === 'production') {
      return shortUrl ? 'https://clbsr.com' : 'https://collabscore.com';
    } else if (env === 'staging') {
      return shortUrl
        ? 'https://staging.clbsr.robinpowered.com'
        : 'https://staging.collabscore.com';
    }
  }

  return window.location.origin;
}
