import React from 'react';
import styled from '@emotion/styled';
import { Facebook, Instagram, LinkedIn, XTwitter } from '../icons/Social';
import { commonColors } from '../../utils/colors';
import { List } from 'antd';
import { Logo } from '../icons/Logo';

const FooterInner = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px 0;
  font-size: 12px;
  color: #333;
  display: grid;
  grid-template-columns: 2fr auto auto auto auto;
  column-gap: 60px;

  @media (min-width: 2048px) {
    max-width: 1400px;
  }

  @media (max-width: 748px) {
    grid-template-columns: auto auto auto auto;
    font-size: 11px;
    row-gap: 40px;
    .footer-logo {
      grid-column: 1 / 5;
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    row-gap: 40px;
    .footer-logo {
      grid-column: 1 / 2;
    }
  }
`;

const SocialBar = styled.div`
  display: flex;
  justify-content: right;
  @media (max-width: 600px) {
    justify-content: left;
  }
  a {
    color: ${commonColors.logo1} !important;
  }
  a:hover {
    color: ${commonColors.logo2} !important;
  }
`;
const Social = styled.div`
  margin-bottom: 5px;
  a {
    margin-right: 5px;
  }
  display: flex;
  align-items: center;
`;

const FooterList = styled(List)`
  h4 {
    margin: 0;
  }
  .ant-list-header {
    padding: 0 0 10px 0;
  }
  .ant-list-item {
    padding: 0 0 3px 0;
  }
  a {
    font-size: 13px;
    color: ${commonColors.logo1};
  }
  a:hover {
    color: ${commonColors.logo2};
`;

function FooterLayout() {
  return (
    <FooterInner>
      <div className={'footer-logo'}>
        <Logo size={{ width: 80, height: 25 }} />
        <p
          style={{
            marginTop: '5px',
            fontFamily: 'Brownllweb, Trebuchet MS, sans-serif',
            fontWeight: 600,
            color: '#333333bb',
          }}
        >
          It's time the office worked for you.
        </p>
      </div>
      <div>
        <FooterList size="small" header={<h4>CollabScore</h4>} split={false}>
          <List.Item>
            <a
              href={
                'https://robinpowered.com/solutions/in-person-collaboration'
              }
            >
              What is this?
            </a>
          </List.Item>
          <List.Item>
            <a href={'https://robinpowered.com/get-started'}>See a demo</a>
          </List.Item>
        </FooterList>
      </div>

      <div>
        <FooterList size="small" header={<h4>Platform</h4>} split={false}>
          <List.Item>
            <a href={'https://robinpowered.com/platform/desk-booking'}>
              Desk Booking
            </a>
          </List.Item>
          <List.Item>
            <a href={'https://robinpowered.com/platform/room-scheduling'}>
              Room Scheduling
            </a>
          </List.Item>
          <List.Item>
            <a href={'https://robinpowered.com/platform/workplace-analytics'}>
              Workplace Analytics
            </a>
          </List.Item>
          <List.Item>
            <a
              href={
                'https://robinpowered.com/solutions/in-person-collaboration'
              }
            >
              CollabScore
            </a>
          </List.Item>
          <List.Item>
            <a href={'https://robinpowered.com/platform/visitor-management'}>
              Visitor Management
            </a>
          </List.Item>
        </FooterList>
      </div>

      <div>
        <FooterList size="small" header={<h4>Robin</h4>} split={false}>
          <List.Item>
            <a href={'https://robinpowered.com/blog'}>Blog</a>
          </List.Item>
          <List.Item>
            <a href={'https://robinpowered.com/about'}>About</a>
          </List.Item>
          <List.Item>
            <a href={'https://robinpowered.com/terms'}>Terms</a>
          </List.Item>
          <List.Item>
            <a href={'https://robinpowered.com/privacy'}>Privacy Policy</a>
          </List.Item>
        </FooterList>
      </div>
      <div>
        <SocialBar>
          <Social>
            <a
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/robin-powered/"
              target="_blank"
              className="footer5_social-link w-inline-block"
            >
              <LinkedIn size={24} />
            </a>
          </Social>
          <Social>
            <a
              rel="noopener noreferrer"
              href="https://www.facebook.com/robinpowered/"
              target="_blank"
              className="footer5_social-link w-inline-block"
            >
              <Facebook size={24} />
            </a>
          </Social>
          <Social>
            <a
              rel="noopener noreferrer"
              href="https://twitter.com/robinpowered"
              className="footer5_social-link w-inline-block"
              target="_blank"
            >
              <XTwitter size={18} />
            </a>
          </Social>
          <Social>
            <a
              rel="noopener noreferrer"
              href="https://www.instagram.com/robinpowered/"
              className="footer5_social-link w-inline-block"
              target="_blank"
            >
              <Instagram size={24} />
            </a>
          </Social>
        </SocialBar>
      </div>
      <div
        style={{
          gridColumn: '1 / -1',
          textAlign: 'left',
          marginTop: '20px',
        }}
      >
        <p>
          &copy; {new Date().getFullYear()} Robin Powered, Inc. All rights
          reserved.
        </p>
      </div>
    </FooterInner>
  );
}

export default FooterLayout;
