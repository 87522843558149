/* eslint-disable max-len */
import { CommonIconProps } from './common';

export interface LogoProps extends CommonIconProps {
  textColor?: string;
  markColor?: string;
}

export const Logo = ({
  size = {
    width: 113,
    height: 30,
  },
  textColor = '#550331',
  markColor = '#A81F6B',
}: LogoProps) => (
  <svg
    width={typeof size === 'number' ? size : size.width}
    height={typeof size === 'number' ? size : size?.height}
    viewBox="0 0 113 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56.0058 26.1081H51.3247L47.7682 17.8622H43.2694V26.1081H39.105V3.80469H47.7074C50.3823 3.80469 52.4085 4.40848 53.7868 5.61514C55.1647 6.82227 55.8538 8.56185 55.8538 10.8335C55.8538 12.3753 55.5195 13.6889 54.8507 14.7739C54.182 15.8592 53.2193 16.6556 51.963 17.1624L56.0058 26.1081ZM50.5495 13.2829C51.1674 12.6644 51.4766 11.848 51.4766 10.8335C51.4766 9.81938 51.1622 9.00782 50.5343 8.39926C49.906 7.7907 48.9636 7.48643 47.7074 7.48643H43.2694V14.2109H47.677C48.9736 14.2109 49.9311 13.9019 50.5495 13.2829Z"
      fill={textColor}
    />
    <path
      d="M57.6442 14.4095C58.3024 13.2028 59.2296 12.2643 60.4255 11.5949C61.621 10.9255 62.9988 10.5908 64.5595 10.5908C66.1197 10.5908 67.4928 10.9255 68.6783 11.5949C69.8638 12.2643 70.7857 13.2028 71.4445 14.4095C72.1028 15.6166 72.4324 16.9911 72.4324 18.5324C72.4324 20.0743 72.1028 21.4487 71.4445 22.6554C70.7857 23.8625 69.8638 24.8005 68.6783 25.4699C67.4928 26.1393 66.1197 26.474 64.5595 26.474C62.9988 26.474 61.621 26.1393 60.4255 25.4699C59.2296 24.8005 58.3024 23.8625 57.6442 22.6554C56.9854 21.4487 56.6562 20.0743 56.6562 18.5324C56.6562 16.9911 56.9854 15.6166 57.6442 14.4095ZM61.7326 21.6969C62.4417 22.5085 63.384 22.914 64.5595 22.914C65.7146 22.914 66.6517 22.5085 67.3713 21.6969C68.0903 20.8858 68.4504 19.8308 68.4504 18.5324C68.4504 17.2345 68.0903 16.1795 67.3713 15.368C66.6517 14.5569 65.7146 14.1509 64.5595 14.1509C63.384 14.1509 62.4417 14.5569 61.7326 15.368C61.023 16.1795 60.6687 17.2345 60.6687 18.5324C60.6687 19.8308 61.023 20.8858 61.7326 21.6969Z"
      fill={textColor}
    />
    <path
      d="M86.8436 11.5035C87.9578 12.1325 88.8346 13.0554 89.4729 14.2725C90.1113 15.4896 90.4305 16.9401 90.4305 18.6236C90.4305 20.2467 90.116 21.6511 89.4881 22.8378C88.8598 24.0245 87.9835 24.9326 86.8588 25.5611C85.7341 26.1896 84.4522 26.5044 83.0135 26.5044C81.7977 26.5044 80.7537 26.2761 79.8826 25.8197C79.0111 25.3633 78.3523 24.8109 77.9068 24.1614L77.694 26.1088H74.1072V3.37939H77.998V12.8119C78.5247 12.0817 79.2391 11.524 80.141 11.1384C81.0425 10.7533 82.0104 10.5603 83.0439 10.5603C84.4622 10.5603 85.7289 10.875 86.8436 11.5035ZM85.2933 21.6816C86.0632 20.8805 86.4484 19.8207 86.4484 18.5019C86.4484 17.1835 86.0632 16.1238 85.2933 15.3222C84.523 14.5211 83.5198 14.1203 82.284 14.1203C81.0477 14.1203 80.0446 14.5211 79.2747 15.3222C78.5043 16.1238 78.1196 17.1835 78.1196 18.5019C78.1196 19.8207 78.5043 20.8805 79.2747 21.6816C80.0446 22.4831 81.0477 22.8835 82.284 22.8835C83.5198 22.8835 84.523 22.4831 85.2933 21.6816Z"
      fill={textColor}
    />
    <path
      d="M96.078 11.0166V26.1087H92.0959V11.0166H96.078Z"
      fill={textColor}
    />
    <path
      d="M111.389 12.2031C112.463 13.2985 113 14.8404 113 16.8282V26.1086H109.048V17.6801C109.048 16.3213 108.734 15.3981 108.106 14.9112C107.478 14.4244 106.779 14.1809 106.009 14.1809C105.117 14.1809 104.316 14.4548 103.607 15.0025C102.898 15.5502 102.543 16.4735 102.543 17.7714V26.1086H98.5918V11.0165H102.331L102.452 12.5074C102.878 11.9193 103.486 11.4477 104.276 11.0926C105.066 10.7379 105.927 10.5601 106.86 10.5601C108.805 10.5601 110.315 11.1078 111.389 12.2032V12.2031Z"
      fill={textColor}
    />
    <path
      d="M91.6934 5.88187C91.6983 7.20368 92.7735 8.27885 94.0954 8.28338C95.4172 8.28785 96.4854 7.21993 96.4805 5.8981C96.4761 4.57629 95.4009 3.50107 94.079 3.4966C92.7572 3.49213 91.689 4.56005 91.6934 5.88187Z"
      fill={textColor}
    />
    <path
      d="M1.39294 16.9204L0.790259 17.5276C-0.262682 18.5887 -0.262682 20.3089 0.789739 21.3698C1.84268 22.4308 3.54948 22.4306 4.60242 21.3695L5.2051 20.7623C6.25752 19.7012 6.25807 17.981 5.2051 16.9201C4.15268 15.8592 2.44591 15.8593 1.39294 16.9204V16.9204Z"
      fill={markColor}
    />
    <path
      d="M17.2448 0.944851L16.6421 1.55204C15.5891 2.61312 15.5891 4.33334 16.6415 5.39423C17.6945 6.45517 19.4013 6.45496 20.4543 5.39391L21.0569 4.78673C22.1094 3.72563 22.1099 2.00541 21.0569 0.944502C20.0045 -0.116408 18.2972 -0.116241 17.2448 0.944851Z"
      fill={markColor}
    />
    <path
      d="M9.01561 24.6064L8.414 25.2128C7.36106 26.274 7.36051 27.9943 8.41348 29.055C9.4659 30.116 11.1732 30.116 12.2256 29.0548L12.8278 28.4484C13.8807 27.3872 13.8807 25.667 12.8278 24.6061C11.7754 23.5452 10.0686 23.5454 9.01561 24.6064Z"
      fill={markColor}
    />
    <path
      d="M28.0778 13.0785L28.6805 12.4713C29.7334 11.4102 29.7334 9.69 28.6805 8.62906C27.6281 7.56816 25.9213 7.56833 24.8683 8.62942L24.2657 9.2366C23.2132 10.2977 23.2127 12.0179 24.2651 13.0788C25.3181 14.1397 27.0249 14.1396 28.0778 13.0785Z"
      fill={markColor}
    />
    <path
      d="M1.39193 13.0794C2.44487 14.1405 4.15167 14.1407 5.20462 13.0798C6.25704 12.0189 6.25704 10.2987 5.20409 9.23759L4.60193 8.6304C3.54899 7.5693 1.84219 7.56915 0.789251 8.63003C-0.26317 9.69091 -0.26317 11.4111 0.789771 12.4722L1.39193 13.0794Z"
      fill={markColor}
    />
    <path
      d="M20.4543 24.6037C19.4013 23.5425 17.6945 23.5422 16.6415 24.6032C15.5891 25.6641 15.5891 27.3842 16.6421 28.4454L17.2437 29.0518C18.2966 30.113 20.0034 30.1133 21.0564 29.0523C22.1088 27.9914 22.1088 26.271 21.0559 25.2101L20.4543 24.6037Z"
      fill={markColor}
    />
    <path
      d="M28.0778 16.9219C27.0249 15.8608 25.3181 15.8607 24.2651 16.9216C23.2127 17.9824 23.2132 19.7027 24.2657 20.7638L24.8683 21.371C25.9213 22.4321 27.6281 22.4322 28.6805 21.3713C29.7334 20.3104 29.7334 18.5902 28.6805 17.5291L28.0778 16.9219Z"
      fill={markColor}
    />
    <path
      d="M9.01616 5.39389C10.0691 6.455 11.7759 6.45516 12.8288 5.39426C13.8813 4.33332 13.8813 2.61312 12.8283 1.55203L12.2256 0.944839C11.1732 -0.116254 9.4659 -0.116392 8.41348 0.944504C7.36051 2.00541 7.36106 3.72561 8.414 4.78672L9.01616 5.39389Z"
      fill={markColor}
    />
    <path
      d="M10.1785 10.4481C9.76227 10.8674 9.55213 11.4155 9.54733 11.9651H9.48975V18.1509H9.54724C9.5524 18.7001 9.76242 19.2476 10.178 19.6666C10.6708 20.163 11.3425 20.3685 11.9837 20.284H17.5175C18.1594 20.3693 18.8321 20.1639 19.3252 19.6668C19.7411 19.2478 19.9512 18.7001 19.9563 18.1509H19.9738V11.9651H19.9559C19.9507 11.4161 19.7406 10.8686 19.3248 10.4496C18.8326 9.95347 18.1614 9.74796 17.5206 9.83208H11.9958C11.3509 9.74341 10.674 9.94852 10.1785 10.4481H10.1785Z"
      fill={markColor}
    />
  </svg>
);
