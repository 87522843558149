import React from 'react';
import { useSizes } from '../contexts/SizeContext';
import styled from '@emotion/styled';
import MapArea from './MapArea';

const ShadowSection = styled(MapArea)``;

interface ShadowProps {
  area1Visible: boolean;
  area2Visible: boolean;
  area3Visible: boolean;
}

const MapShadow: React.FC<ShadowProps> = ({
  area1Visible,
  area2Visible,
  area3Visible,
}) => {
  const { sizes } = useSizes();

  return (
    <div>
      {/* I have to add 2 and 1 to the dimensions and I don't know why. There's probably a good reason, like a border. */}
      {area1Visible && (
        <ShadowSection
          style={{
            height: sizes.mapArea1.height + 2,
            width: sizes.mapArea1.width + 2,
          }}
        ></ShadowSection>
      )}
      {area2Visible && (
        <ShadowSection
          style={{
            borderTop: '0',
            marginTop: '-2px',
            height: sizes.mapArea2.height + 2,
            width: sizes.mapArea2.width + 1,
          }}
        ></ShadowSection>
      )}
      {area3Visible && (
        <ShadowSection
          style={{
            borderTop: '0',
            marginTop: '-2px',
            height: sizes.mapArea3.height + 2,
            width: sizes.mapArea3.width + 1,
          }}
        ></ShadowSection>
      )}
    </div>
  );
};

export default MapShadow;
