import { ControlValues } from '../components/Controls';
import { getAverageMeetingSize, getRoomsNeeded } from './occupancy';
import { computeOccupancy } from '../hooks/mapAreaHooks';
import { OccupancyControlValues } from '../components/OccupancyControls';
import data from '../data/percentiles.json';
import { ResultSummaries } from '../interfaces/types';

export interface CalculationResult {
  occupancyScore: number;
  adhocCollabScore: number;
  plannedCollabScore: number;
  collabScore: number;

  insights: {
    averageOccupancy: number;
    peakOccupancy: number;
  };

  recommendations: {
    callBooths: number;
    rooms: number;
    desks: number;
    targetOccupancy: number;
  };
}

export interface SimulationParameters {
  variance?: 0 | 1 | 2 | 3 | 4 | 5;
  includeUSHolidays?: boolean;
  numberOfWeeks?: number;
}

export const calculate = (
  values: ControlValues,
  {
    variance = 2,
    includeUSHolidays = false,
    numberOfWeeks = 1,
  }: SimulationParameters = {}
): CalculationResult => {
  let occupancyScorePeople = 0;
  let occupancyScoreDesks = 0;
  let plannedCollabScoreGoodMeetings = 0;
  let plannedCollabScoreTotalMeetings = 0;
  let adhocCollabScoreNonLonelyDesks = 0;
  let adhocCollabScoreLonelyDesks = 0;

  const averageMeetingSize = getAverageMeetingSize(
    values.culture.roomCollaborationFactor
  );

  let totalOccupancy = 0;
  let peakOccupancy = 0; // Move peak occupancy outside the weekly loop

  for (let i = 0; i < numberOfWeeks; i++) {
    const calculateOccupancyChange = (variance: number) => {
      const occupancyVariance = variance * 0.08;
      return Math.random() * occupancyVariance - occupancyVariance;
    };

    const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

    let thisWeeksPeople = 0;

    const amendedOccupancy = {
      monday: 0.5,
      tuesday: 0.5,
      wednesday: 0.5,
      thursday: 0.5,
      friday: 0.5,
    };

    for (const day of daysOfWeek) {
      amendedOccupancy[day as keyof typeof values.culture.occupancy] =
        Math.max(
          values.culture.occupancy[
            day as keyof typeof values.culture.occupancy
          ] /
            100 +
            calculateOccupancyChange(variance),
          0
        ) ** 2.8;
    }

    for (const day of daysOfWeek) {
      const thisDaysPeople = Math.min(
        values.office.desks,
        values.office.desks *
          amendedOccupancy[day as keyof typeof values.culture.occupancy]
      );

      // Update peakOccupancy if thisDaysPeople is greater
      if (thisDaysPeople > peakOccupancy) {
        peakOccupancy = thisDaysPeople;
      }

      // Add thisDaysPeople to totalOccupancy for average calculation
      totalOccupancy += thisDaysPeople;
      thisWeeksPeople += thisDaysPeople;
    }

    occupancyScorePeople += thisWeeksPeople;
    occupancyScoreDesks += values.office.desks * 5;

    const plannedCollabScoreTotalMeetingsThisWeek =
      (values.culture.avgMeetingsPerDay * thisWeeksPeople) / averageMeetingSize;
    plannedCollabScoreTotalMeetings += plannedCollabScoreTotalMeetingsThisWeek;
    plannedCollabScoreGoodMeetings +=
      plannedCollabScoreTotalMeetingsThisWeek *
      (values.culture.roomCollaborationFactor / 100) ** 2;

    let lonelyDesks = 0;
    let nonLonelyDesks = 0;
    // iterate over all the occupancy rates for each day in values.culture.occupancy
    for (const day in values.culture.occupancy) {
      const currentDay: keyof OccupancyControlValues =
        day as keyof OccupancyControlValues;
      const desksArray = computeOccupancy(
        values.office.desks,
        values.culture.occupancy[currentDay] / 100,
        values.culture.workingProximity / 100
      );

      // iterate over desks 6 at a time. if ever there is just one person at a desk,
      // in a group of 6, then lonelyDesks++. Otherwise, add all the non-empty desks to nonLonelyDesks.
      for (let i = 0; i < desksArray.length; i += 3) {
        const group = desksArray.slice(i, i + 3);
        const peopleInPod = group.filter((d) => d).length;
        if (peopleInPod === 1) {
          // noop
          lonelyDesks++;
        } else {
          nonLonelyDesks += peopleInPod;
        }
      }
    }

    adhocCollabScoreLonelyDesks += lonelyDesks;
    adhocCollabScoreNonLonelyDesks += nonLonelyDesks;
  }

  const averageOccupancy = totalOccupancy / numberOfWeeks / 5;

  const badMeetings =
    (plannedCollabScoreTotalMeetings - plannedCollabScoreGoodMeetings) /
    numberOfWeeks /
    5;
  const callBoothsNeeded = Math.ceil((badMeetings * 0.8) / 6);

  const targetOccupancyRate = 0.8;
  const desiredDesks = peakOccupancy / targetOccupancyRate;
  const additionalDesksNeeded = Math.ceil(desiredDesks - values.office.desks);
  const roomsNeeded = getRoomsNeeded(
    peakOccupancy,
    values.office.privateOffices,
    values.culture.avgMeetingsPerDay,
    averageMeetingSize
  );

  const occupancyScore = occupancyScorePeople / occupancyScoreDesks;

  const adhocDenom =
    adhocCollabScoreLonelyDesks + adhocCollabScoreNonLonelyDesks;
  const adhocCollabScore =
    adhocDenom === 0
      ? 0
      : (adhocCollabScoreNonLonelyDesks / adhocDenom) **
        (values.culture.departmentsMix ? 1.4 : 2);

  const plannedCollabScore =
    plannedCollabScoreTotalMeetings === 0
      ? 0
      : plannedCollabScoreGoodMeetings / plannedCollabScoreTotalMeetings;

  const collabScore =
    (occupancyScore + adhocCollabScore + plannedCollabScore) / 3;

  return {
    occupancyScore: getPercentile(occupancyScore, 'occupancy'),
    plannedCollabScore: getPercentile(plannedCollabScore, 'planned_collab'),
    adhocCollabScore: getPercentile(adhocCollabScore, 'adhoc_collab'),
    collabScore: getPercentile(collabScore, 'total'),
    insights: {
      averageOccupancy,
      peakOccupancy,
    },
    recommendations: {
      callBooths: callBoothsNeeded,
      rooms: Math.ceil(roomsNeeded + roomsNeeded * 0.2 - values.office.rooms),
      desks: additionalDesksNeeded,
      targetOccupancy: 0.8,
    },
  };
};

interface PercentilesData {
  occupancy: Record<string, number>;
  planned_collab: Record<string, number>;
  adhoc_collab: Record<string, number>;
  total: Record<string, number>;
}

// load JSON from ./data/percentiles.json
// and match the value to a percentile
const getPercentile = (
  value: number,
  metric: 'occupancy' | 'planned_collab' | 'adhoc_collab' | 'total'
): number => {
  const percentileData: PercentilesData = data;
  const roundedValue = Math.round(value * 100);
  return percentileData[metric]['' + roundedValue] / 100;
};

export const getDefaultSummaries = (
  result: CalculationResult
): ResultSummaries => {
  return {
    collabScoreSummary: collabScoreScoreToCopy(result.collabScore),
    occupancySummary: occupancyScoreToCopy(result.occupancyScore),
    adhocSummary: adhocCollabScoreToCopy(result.adhocCollabScore),
    plannedSummary: plannedCollabScoreToCopy(result.plannedCollabScore),
  };
};

const collabScoreScoreToCopy = (score: number): string => {
  if (score < 0.4) {
    return (
      'This score reflects significant challenges in collaboration. Consider ' +
      'evaluating and adjusting your workplace policies and layout to enhance teamwork.'
    );
  } else if (score < 0.7) {
    return (
      'A solid foundation for collaboration is present. Enhance your strategies ' +
      'to further increase effective employee interaction and productivity.'
    );
  } else if (score < 0.85) {
    return (
      'You are achieving great collaboration levels. Focus on maintaining ' +
      'these practices while exploring new areas for minor improvements.'
    );
  } else {
    return (
      'Outstanding collaboration environment. Continue to implement policies ' +
      'that build a culture of in-person collaboration.'
    );
  }
};

const occupancyScoreToCopy = (score: number): string => {
  if (score < 0.4) {
    return 'Your occupancy rate is lower than most, suggesting a lack of engagement.';
  } else if (score < 0.7) {
    return 'Occupancy rates are moderate.';
  } else if (score < 0.85) {
    return 'Your occupancy rates are high';
  } else {
    return 'You excel in fostering high occupancy rates.';
  }
};

const plannedCollabScoreToCopy = (score: number): string => {
  if (score < 0.4) {
    return (
      'Low levels of planned collaboration indicate missed opportunities for ' +
      'team synergy. Increase the frequency of in-person team meetings.'
    );
  } else if (score < 0.7) {
    return (
      'Planned collaboration is effective. To build on this, promote more ' +
      'regular and inclusive team meetings.'
    );
  } else if (score < 0.85) {
    return (
      'Your team regularly collaborates well in planned settings. Continue ' +
      'these practices, ensuring all team members are frequently engaged.'
    );
  } else {
    return (
      'Your planned collaborations are exemplary. Continue to facilitate ' +
      'these interactions as a model for successful teamwork.'
    );
  }
};

const adhocCollabScoreToCopy = (score: number): string => {
  if (score < 0.4) {
    return (
      'Sparse ad hoc collaboration suggests potential isolation; consider ' +
      'restructuring the physical workspace to promote more organic interactions.'
    );
  } else if (score < 0.7) {
    return (
      'Ad hoc collaboration is beginning to function; focus on strategic ' +
      'seating arrangements to further enhance spontaneous interactions.'
    );
  } else if (score < 0.85) {
    return (
      'Your setup promotes substantial ad hoc interaction. Continue to foster ' +
      'this environment while considering minor tweaks for improvement.'
    );
  } else {
    return (
      'You excel in fostering ad hoc collaborations. Keep up the great work ' +
      'and ensure the environment remains dynamic and engaging.'
    );
  }
};
