/* eslint-disable max-len */
import { CommonIconProps } from './common';

export const RoomCollaborationFactorLow = ({ size = 24 }: CommonIconProps) => (
  <svg
    width={typeof size === 'number' ? size : size.width}
    height={typeof size === 'number' ? size : size.height}
    fill="currentColor"
    viewBox="0 0 50 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m42.77 10.242-15.504-.168c.003-.037-.073-3.466-.073-3.466-.655.012.215-.732-2.344-1.498l-4.597.17c-2.499.823-1.403 1.411-2.156 1.371l.056 3.472c.029.016-16.924.12-16.924.12-.677 0-1.228.546-1.228 1.217v13.452c.037.855.994 1.35 1.723.891.309-.193.504-.526.52-.89v-9.704h2.54l13.193.033.17 1.362c.277 1.163.132.912.692 1.261l.652.13v.596c-.862 0-1.4.925-.97 1.664.2.343.57.555.97.555v3.966c.037.855.994 1.35 1.724.891.31-.194.504-.528.519-.89v-3.965l1.959.018v3.965c.025.855.977 1.362 1.71.912.322-.196.522-.539.533-.912v-3.967c.862 0 1.401-.925.97-1.664a1.119 1.119 0 0 0-.97-.555v-.595l.532-.085c.619 0 .8-1.307.807-1.337l.175-1.376 1.06-.007h13.248v9.703c-.036.855.874 1.428 1.64 1.032.386-.2.621-.601.602-1.032V11.46c0-.671-.551-1.217-1.229-1.217v-.001ZM2.243 12.464l39.537.056.002.457-39.54.01v-.523h.001Zm21.45 5.549v.595l-1.96-.018v-.596l1.96.019Zm2.286-7.101-6.586.031-.037-3.326 6.627-.015c.03.01-.004 3.31-.004 3.31Z" />
    <circle
      cx="22.5"
      cy="3.5"
      r="2.25"
      stroke="currentColor"
      fill="none"
      strokeWidth={'1.5'}
    />
  </svg>
);

export default RoomCollaborationFactorLow;
