import React from 'react';
import GaugeComponent from 'react-gauge-component';

interface gaugeProps {
  title: string;
  percent: number;
}

const Gauge: React.FC<gaugeProps> = ({ title, percent }) => {
  return (
    <GaugeComponent
      type={'semicircle'}
      marginInPercent={{ top: 0.06, bottom: 0.0, left: 0.06, right: 0.06 }}
      arc={{
        gradient: false,
        width: 0.15,
        colorArray: ['#ffffff33', '#ffffff88', '#ffffffcc', '#ffffffff'],
        subArcs: [
          {
            limit: 40,
          },
          {
            limit: 70,
          },
          {
            limit: 85,
          },
          {
            limit: 100,
          },
        ],
        cornerRadius: 2,
        padding: 0.03,
      }}
      value={percent * 100}
      pointer={{
        type: 'arrow',
        color: '#fff',
        width: 20,
      }}
      labels={{
        valueLabel: {
          formatTextValue: (value: string | number) => `${value}`,
          style: {
            fontWeight: '800',
            fontSize: 80,
            color: '#ffffffee',
            fill: '#ffffffee',
            textShadow: 'none',
          },
        },
        tickLabels: {
          hideMinMax: true,
        },
      }}
    />
  );
};

export default Gauge;
