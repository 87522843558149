import { CalculationResult } from '../utils/calculate';
import { ControlValues } from '../components/Controls';
import { saveToLocalStorage } from '../utils/localStorage';

export async function submitResultAndSave(
  values: ControlValues,
  result: CalculationResult,
  minWaitTime = 5000
) {
  let apiError = null;
  const timeoutPromise = new Promise((resolve) =>
    setTimeout(resolve, minWaitTime)
  );
  const apiPromise = submitResult(values, result).catch((error) => {
    apiError = error;
  });

  const [_, apiResponse] = await Promise.all([timeoutPromise, apiPromise]);

  if (apiError) {
    throw apiError;
  }

  saveToLocalStorage(values, result, {
    collabScoreSummary: apiResponse.collabScoreSummary,
    occupancySummary: apiResponse.occupancySummary,
    adhocSummary: apiResponse.adhocSummary,
    plannedSummary: apiResponse.plannedSummary,
  });

  return apiResponse;
}

export async function submitResult(
  values: ControlValues,
  result: CalculationResult
) {
  const response = await fetch(`${window.origin}/api/result`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      result: {
        insights: result.insights,
        occupancyScore: result.occupancyScore,
        adhocCollabScore: result.adhocCollabScore,
        plannedCollabScore: result.plannedCollabScore,
        collabScore: result.collabScore,
      },
      inputs: values,
    }),
  });

  if (!response.ok) {
    throw new Error(`API request failed with status ${response.status}`);
  }

  return response.json();
}
