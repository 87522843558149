import { CalculationResult } from './calculate';
import { ControlValues } from '../components/Controls';
import { ResultSummaries } from '../interfaces/types';

type StoredResult = {
  inputs: ControlValues;
  result: CalculationResult;
  resultSummary: ResultSummaries;
};

export const saveIntroStatusToLocalStorage = (hasSeenIntro: boolean) => {
  // Store the hasSeenIntro flag in localStorage
  localStorage.setItem('hasSeenIntro', JSON.stringify(hasSeenIntro));
};

export const loadIntroStatusFromLocalStorage = () => {
  const storedHasSeenIntro = localStorage.getItem('hasSeenIntro');

  return storedHasSeenIntro ? JSON.parse(storedHasSeenIntro) : false;
};

export const saveToLocalStorage = (
  values: ControlValues,
  result: CalculationResult,
  resultSummary: ResultSummaries
) => {
  const storedResultArray = localStorage.getItem('resultArray');
  const resultArray: StoredResult[] = storedResultArray
    ? JSON.parse(storedResultArray)
    : [];
  if (resultArray.length >= 10) {
    resultArray.pop();
  }

  // Unshift the new values and result to the arrays
  resultArray.unshift({
    inputs: values,
    result,
    resultSummary,
  });

  localStorage.setItem('resultArray', JSON.stringify(resultArray));
};

export const loadFromLocalStorage = () => {
  // Retrieve the stored arrays from localStorage
  const storedValuesArray = localStorage.getItem('valuesArray');
  const storedResultArray = localStorage.getItem('resultArray');

  // Parse the stored arrays
  const valuesArray = storedValuesArray ? JSON.parse(storedValuesArray) : [];
  const resultArray = storedResultArray ? JSON.parse(storedResultArray) : [];

  return { valuesArray, resultArray };
};

export const loadOldResultFromLocalStorage = (index: number) => {
  const { valuesArray, resultArray } = loadFromLocalStorage();

  // Retrieve the values and result at the specified index
  const oldValues = valuesArray[index];
  const oldResult = resultArray[index];

  return { oldValues, oldResult };
};
